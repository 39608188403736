import { WebChatProvider } from './context';
import Bubble from './components/Bubble';
import { AppContainer } from './styledComponents';
import Faqs from './components/Faqs';
import ShadowDomContainer from './components/ShadowDomContainer';
import locales from './locales';
import { QueryClientProvider } from 'react-query';
import queryClient from './api/client';

function App({ tokenUrl, tokenMethod, faqOnly, qnaUrl, kn, autoCompleteKn, lang, onboarding, onboardingMobile, autoCompleteUrl, privacyPolicyUrl = locales(lang, 'privacy-url'), onboardingAnimationDelay }) {
  const getChatView = () => {
    if (faqOnly) {
      return <Faqs faqOnly={faqOnly} qnaUrl={qnaUrl} kn={kn} />
    }

    return <Bubble qnaUrl={qnaUrl} kn={kn} />
  }

  return (
    <WebChatProvider autoCompleteUrl={autoCompleteUrl} privacyPolicyUrl={privacyPolicyUrl} autoCompleteKn={autoCompleteKn} kn={kn} faqOnly={faqOnly} tokenUrl={tokenUrl} tokenMethod={tokenMethod} lang={lang} onboarding={onboarding} onboardingMobile={onboardingMobile} onboardingAnimationDelay={onboardingAnimationDelay} >
      <ShadowDomContainer>
        <QueryClientProvider client={queryClient}>
          <AppContainer className="app web-chat-app">
            {getChatView()}
          </AppContainer>
        </QueryClientProvider>
      </ShadowDomContainer>
    </WebChatProvider>
  );
}

export default App;
