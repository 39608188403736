import styled from "@emotion/styled"
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useWebChat } from "../../context";
import ErrorIcon from "../../icons/Error";
import locales from "../../locales";
import TooltipSlider from "../Slider"
import FeedbackFormComments from "./FeedbackFormComments";
import { ErrorMessage, ErrorMessageBox } from "./Styled";

const StyledDivSlider = styled.div`
  display: flex;
  color: white;
  justify-content: space-between;
  margin-top: 0.5em;
`

const StyledTextWrapper = styled.div`
  margin: 0 0 90px 0;

  @media screen and (min-width: 640px) {
    margin: 0 1.5em 90px 1.5em;
  }
`

const StyledSmallText = styled.span`
  font-size: 12px;
`

const FeedbackSlider = ({
  sliderInputName,
  sliderLabelName,
  commentInputName,
  commentLabelName,
  rateMinLabel,
  rateMaxLabel,
  commentQuestion = 'rateMotivation',
  customRateLabel = false,
  min = 0,
  max = 10,
  error = {},
}) => {
  const { lang } = useWebChat();
  const { register, setValue, watch } = useFormContext();
  register(sliderInputName)
  const sliderInputValue = watch(sliderInputName)

  const handleCallback = useCallback((value) => {
    setValue(sliderInputName, value)
  }, [sliderInputName, setValue])

  return (
    <div>
      <StyledTextWrapper>
        <label>{locales(lang, sliderLabelName)}</label>
      </StyledTextWrapper>
      <TooltipSlider required defaultValue={sliderInputValue} callback={handleCallback} min={min} max={max} />
     
      <StyledDivSlider>
        {customRateLabel &&
          <>
            {sliderInputValue == null && (
              <>
                <StyledSmallText>{locales(lang, rateMinLabel)}</StyledSmallText>
                <StyledSmallText>{locales(lang, rateMaxLabel)}</StyledSmallText>
              </>
            )}
            {sliderInputValue != null &&
              <div style={{ width: '100%', textAlign: 'center' }}>
                <StyledSmallText style={{}}>{locales(lang, `feedbackRate${sliderInputValue}`)}</StyledSmallText>
              </div>
            }
          </>
        }
        {!customRateLabel && (
          <>
            <StyledSmallText>{locales(lang, 'rate0')}</StyledSmallText>
            <StyledSmallText>{locales(lang, 'rate10')}</StyledSmallText>
          </>
        )}
      </StyledDivSlider>
      {sliderInputValue == null && error?.[sliderInputName] && <ErrorMessageBox><ErrorMessage><ErrorIcon />{locales(lang, 'errorRequired')}</ErrorMessage></ErrorMessageBox>}
      <hr />
      <div style={{ textAlign: 'center', marginTop: '2em', marginBottom: '1.5em' }}>
        <label>{locales(lang, commentQuestion)}</label>
      </div>
      <FeedbackFormComments error={error} inputName={commentInputName} label={commentLabelName} className="textarea-comments" commentMaxLength={250} />
    </div>
  )
}

export default FeedbackSlider
