import { datadogLogs } from '@datadog/browser-logs'

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const ping = (url, timeout = 30000) => {
  return new Promise((resolve, reject) => {
    if (isValidUrl(url) === false) {
      reject(false);
    }

    try {
      fetch(url, {
        method: "POST",
        mode: "no-cors",
        body: "{}"
      })
        .then(() => {
          resolve(true);
        })
        .catch(() => resolve(false));
      setTimeout(() => {
        resolve(false);
      }, timeout);
    } catch (e) {
      reject(e);
    }
  });
};

const datadog = async () => {
  const env = process.env.NODE_ENV;
  const rootElement = document.querySelector(".croixbleueapp[data-datadog-token]")

  const token = rootElement?.dataset?.datadogToken || '';
  const proxy = rootElement?.dataset?.datadogProxy || '';

  const isBlocked = await ping(
    `https://logs.browser-intake-datadoghq.com/api/v2/logs?dd-api-key=${token}`
  );

  const datadogConfig = {
    clientToken: token,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    service: "chatbot",
    env,
    version: "no-proxy",
    beforeSend: (log) => {
      if (log.status === "error") {
        if (
          log.message.includes("api.segment.io") ||
          log.message.includes("XHR error") ||
          log.message.includes("ad.doubleclick.net") ||
          log.message.includes("googlesyndication.com") ||
          log.message.includes("Network Error") ||
          log.message.includes("yimg.com")
        ) {
          datadogLogs.logger.warn(log.message, log);
          return false;
        }
      }
    }
  }

  if (!isBlocked) {
    datadogConfig.proxyUrl = proxy;
    datadogConfig.version = "with-proxy";
  }

  datadogLogs.init(datadogConfig);

  datadogLogs.setLoggerGlobalContext({
    host: window.location.host,
    env
  });

  /**
   * Overload logging function to put context in context object.
   * Like that context data will be not mix with datadog default attributs
   */
  const _debug = datadogLogs.logger.debug;
  const MISSING_MESSAGE = "Missing message";
  datadogLogs.logger.debug = function (message, contextObj) {
    _debug.apply(this, [message || MISSING_MESSAGE, { context: contextObj }]);
  };
  const _info = datadogLogs.logger.info;
  datadogLogs.logger.info = function (message, contextObj) {
    _info.apply(this, [message || MISSING_MESSAGE, { context: contextObj }]);
  };
  const _warn = datadogLogs.logger.warn;
  datadogLogs.logger.warn = function (message, contextObj) {
    _warn.apply(this, [message || MISSING_MESSAGE, { context: contextObj }]);
  };
};

datadog()
