import styled from "@emotion/styled";
import { useWebChat } from "../../context";
import useDebounce from "../../helpers/useDebounce";
import CloseIcon from "../../icons/CloseIcon";
import locales from "../../locales";
import { ButtonIcon } from "../Header/styled";
import { MomoizedSvgSpinner } from "../Spinner";
import useAutoCompleteQuery from "./query";

const StyledContainer = styled.section`
  padding-top: 16px;
  position: absolute;
  top: auto;
  height: auto;
  bottom: 130px;
  background: #00588A;
  border-radius: 16px 16px 0px 0px;
  width: 100%;
`

const StyledQuestion = styled.div`
  padding-left: 16px;
  width: 80%;

  p {
    padding: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    @media screen and (min-width: 640px) {
      font-size: 16px;
    }

    line-height: 150%;
    color: white;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background: #0b3349;
    }
  }
`

const StyledSpan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #FEFFFF;
  padding-left: 24px;
  display: inline-block;
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
`

const Autocomplete = () => {
  const { userInputText, reduxStore, autoCompleteKn, autoCompleteUrl, lang, showAutocomplete, setShowAutocomplete, isHandover } = useWebChat();
  const deboundedSearch = useDebounce(userInputText)

  const handleClick = (question) => {
    reduxStore.current.dispatch({
      type: 'WEB_CHAT/SEND_MESSAGE',
      payload: {
        text: question
      }
    });

    reduxStore.current.dispatch({
      type: 'WEB_CHAT/SET_SEND_BOX',
      payload: {
        text: ""
      }
    });

    handleCloseBtn()
  }

  const handleSuccessCallback = () => {
    setShowAutocomplete(true);
  }

  const handleCloseBtn = () => {
    setShowAutocomplete(false);
  }

  const { data, isLoading, isFetching, isFetched, isStale } = useAutoCompleteQuery(deboundedSearch, handleSuccessCallback, `${autoCompleteKn}.${lang}.qna`, autoCompleteUrl)

  if (isHandover) {
    return null
  }

  if (!showAutocomplete || userInputText === "") {
    return null
  }

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledSpan>Suggestions</StyledSpan>
        <ButtonIcon onClick={handleCloseBtn}><CloseIcon /></ButtonIcon>
      </StyledHeader>
      {(isStale || isLoading || isFetching) && <MomoizedSvgSpinner style={{ marginLeft: '16px', width: '50px', height: '50px', background: "#00588a 0 0", color: "#e1f5ff" }} />}
      {(isFetched && (data == null || data.length === 0)) && (
        <StyledQuestion><p>{locales(lang, 'noSuggestionFound')}</p></StyledQuestion>
      )}
      {data?.map(({ q, id }) =>
        <StyledQuestion key={id}>
          <p onClick={() => handleClick(q)}>{q}</p>
        </StyledQuestion>)}
    </StyledContainer>
  )
}

export default Autocomplete;
