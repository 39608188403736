const Robot = (props) => {
  return (
    <svg
      width={23}
      height={27}
      viewBox="0 0 23 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx={2.487} cy={1.884} rx={1.865} ry={1.884} fill="#077AC0" />
      <ellipse cx={20.514} cy={1.884} rx={1.865} ry={1.884} fill="#077AC0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.865 13.186V2.512h1.243v10.674H1.865zM19.892 13.814V2.512h1.243v11.302h-1.243z"
        fill="#077AC0"
      />
      <ellipse cx={11.5} cy={15.384} rx={11.5} ry={11.616} fill="#0094D7" />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={3}
        width={23}
        height={24}
      >
        <ellipse cx={11.5} cy={15.384} rx={11.5} ry={11.616} fill="#0094D7" />
      </mask>
      <g mask="url(#prefix__a)" fill="#2BA3EC">
        <path d="M3.5 4.272H5v21.717H3.5zM18 4.272h1.5v21.717H18z" />
      </g>
      <rect
        x={4.448}
        y={10.718}
        width={14.104}
        height={6.615}
        rx={3.307}
        fill="#fff"
      />
      <ellipse cx={15.278} cy={14.025} rx={1.259} ry={1.272} fill="#0094D7" />
      <ellipse cx={7.722} cy={14.025} rx={1.259} ry={1.272} fill="#0094D7" />
      <path
        d="M13.9 20.385c.344 0 .629.284.56.624a3.03 3.03 0 01-2.96 2.43 3.031 3.031 0 01-2.96-2.43c-.069-.34.216-.624.56-.624h4.8z"
        fill="#fff"
      />
    </svg>
  )
}

export default Robot
