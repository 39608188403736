import { useWebChat } from "../../context";
import styled from '@emotion/styled';
import React, { useMemo } from "react";

const StyledSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-out;
  opacity: 1;
  position: absolute;
  top: 3.75em;
  left: 0;
  bottom: 3.375em;
  right: 0;
  background: #e1f5ff;
  pointer-events: none;
  height: auto !important;
`

export const MomoizedSvgSpinner = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: "auto",
        background: "#e1f5ff 0 0",
        color: "#00588a",
        ...props.style
      }}
      width={200}
      height={200}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      display="block"
      {...props}
    >
      <path d="M10 50a40 40 0 0080 0 40 42 0 01-80 0" fill="currentColor">
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 51;360 50 51"
        />
      </path>
    </svg>
  )
}


const Spinner = (props) => {
  const { spinnerDisplayed } = useWebChat();

  return (
    <StyledSpinner style={{ opacity: spinnerDisplayed ? 1 : 0 }}>
      <MomoizedSvgSpinner props={props} />
    </StyledSpinner>
  )
}

export default React.memo(Spinner);