const getPositionY = (element) => {
  let yPosition = 0;

  while(element) {
      yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
      element = element.offsetParent;
  }

  return yPosition;
}

const doScrolling = (elementY, container) =>
  new Promise((resolve) => {
    const startingY = container.scrollTop || container.pageYOffset || 0;
    const diff = elementY - startingY;
    const duration = 200 + Math.abs(diff / 4);
    let start;

    const next = () =>
      window.requestAnimationFrame((timestamp) => {
        if (!start) start = timestamp;
        const time = timestamp - start;
        const percent = Math.min(time / duration, 1);
        container.scrollTo(0, startingY + diff * percent);
        return time < duration ? next() : resolve();
      });

    next();
  });

const getTargetElement = (targetSelector, shadowSelector) => {
  if (!targetSelector) {
    return null;
  }

  if (shadowSelector != null) {
    return document.querySelector(shadowSelector).shadowRoot.querySelector(targetSelector);
  }

  return document.querySelector(targetSelector);
};

const scrollTo = async (targetSelector, container = window, shadowSelector = null, pageOffset = 0) => {
  const target = getTargetElement(targetSelector, shadowSelector);

  if (!target) {
    return;
  }

  const offset = Math.max(((container.offsetHeight || container.innerHeight) + pageOffset) * 0.1, 0);
  const targetOffset = getPositionY(target) - offset;
  const collapse = target.querySelector('[aria-expanded="false"]');

  if (collapse != null) {
    collapse.click();
  }

  doScrolling(targetOffset, container);
};

export default scrollTo;
