import styled from "@emotion/styled";

export const StyledTitle = styled.h2`
  margin-top: 3.313em !important;
  margin-bottom: 1.625em !important;
  display:flex;
  
  span {
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.5em;
    letter-spacing: -0.18px;
    color: var(--color-black);
    white-space: nowrap;
  }

  .desktop-faq-only & span {
    white-space: normal;
  }

  &:before {
    counter-increment: section;
    content: counter(section);
    height: 2.375em;
    width: 2.375em;
    border-radius: 100%;
    margin-right: 14px;
    background: var(--color-white);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary);
    flex-shrink: 0;
  }

  .desktop-faq-only & {
    @media screen and (min-width: 1024px) {
      font-size: 1.5em;
      line-height: 2em;
      letter-spacing: -0.3px;
    }
  }
`;

export const ButtonCateg = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.75em 0px !important;
  width: 100%;
  height: auto;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 1.375em 5.375em 1.438em 1.063em;
  color: var(--color-black);
  background: var(--color-white);
  border: 2px solid var(--color-gray);
  box-sizing: border-box;
  position: relative;

  &:hover {
    border: 2px solid var(--color-primary);
  }

  &.active {
    border: 2px solid var(--color-primary);
  }

  &:after {
    font-size: 16px;
    content: '';
    display: block;
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    border: 2px solid #077AC0;
    box-sizing: border-box;
    border-radius: 100%;
    right: 1.5em;
    top: auto;
    bottom: auto;
    margin: auto;
  }

  &.active:before {
    content: '';
    display: block;
    position: absolute;
    background: #077AC0;
    border-radius: 100%;
    right: 1.5em;
    width: 1.5em;
    height: 1.5em;
    transform-origin: center;
    transform: scale(0.6);
  }

  .desktop-faq-only & {
    @media screen and (min-width: 1024px) {
      margin: 0 !important;
    }
  }

`
export const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
 
 .desktop-faq-only & {
    @media screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1.5em;
      grid-row-gap: 1.5em;
      grid-auto-rows: 1fr;
      padding-top: 0.5em;

      & > li > label {
        height: 100%;
      }
    }
  }
`

export const StyledCategName = styled.span`
  font-weight: normal;
  font-size: 1em;
  line-height: 1.5em;
  font-feature-settings: 'salt' on;
  color: var(--color-black);
`

export const StyledCategRadio = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

export const MainTitle = styled.h2`
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: center;
  letter-spacing: -0.2px;
  margin: 2.875em 0px 2em 0px;
  color: var(--color-black);

  .desktop-faq-only & {
    @media screen and (min-width: 1024px) {
      font-size: 2.125em;
      line-height: 2.5em;
      letter-spacing: -0.4px;
      margin-bottom: 51px;
    }
  } 
`

export const FaqButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
  padding-bottom: 1.25em;
  cursor: pointer;

  .one-item & {
    padding-bottom: 0;
  }
`;

export const StyledListMargin = styled.ul`
  list-style:none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const StyledItem = styled.li`
  width: 100%;
  position: relative;


  @media screen and (min-width: 1024px) {
    max-width: 50%;
  }
`

export const StatusBar = styled.div`
  width: 100%;
  height: 0.438em;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
  background: var(--color-primary-dark);
  transform: scaleY(28.5714285714%);
  transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
  transform-origin: bottom;

  &.active {
    transform: scaleY(100%);
  }

  .one-item &.active {
    display: none;
  }
`

export const KbNameSpan = styled.span`
  font-weight: bold;
  font-size: 1.25em;
  line-height: 1.5em;
  text-align: center;
  letter-spacing: -0.2px;
  max-width: 66%;
  margin-top: 0.5em;
  color: var(--color-black);

  .desktop-faq-only & {
    @media screen and (min-width: 1024px) {
      font-size: 1.5em;
      line-height: 2em;
      text-align: center;
      letter-spacing: -0.3px;
      margin-bottom: 0.5em;
    }
  }
`;
