const BlankIcon = (props) => {
    return (
      <svg
        width={38}
        height={38}
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19 38c10.493 0 19-8.507 19-19S29.493 0 19 0 0 8.507 0 19s8.507 19 19 19z"
          fill="#E3F7FF"
        />
      </svg>
    )
  }
  
  export default BlankIcon
  