
const AssuranceVoyageIcon = (props) => {
  return (
    <svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 38c10.493 0 19-8.507 19-19S29.493 0 19 0 0 8.507 0 19s8.507 19 19 19z"
        fill="#fff"
      />
      <path
        d="M25.01 27.396c.266-.266.374-.662.273-1.022l-2.362-9.122 3.904-3.9c.583-.582.67-1.546.115-2.158a1.528 1.528 0 00-2.218-.057l-3.962 3.957-9.132-2.36c-.36-.1-.757.007-1.023.273a1.07 1.07 0 00.238 1.69l6.669 3.641-3.954 3.95-2.103-.302a.84.84 0 00-.898 1.131c.07.182.202.334.372.43l2.269 1.259 1.26 2.266a.839.839 0 001.563-.525l-.302-2.1 3.96-3.958 3.645 6.662a1.06 1.06 0 001.686.245z"
        fill="#077AC0"
      />
    </svg>
  )
}

export default AssuranceVoyageIcon
