import * as React from "react"

function Refresh(props) {
  return (
    <svg
      width={24}
      height={20}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7h5a1 1 0 010 2H1a1 1 0 01-1-1V2a1 1 0 012 0v5zM22 13h-5a1 1 0 110-2h6a1 1 0 011 1v6a1 1 0 11-2 0v-5z"
        fill="#077AC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.433 6.666a1 1 0 11-1.886.668A8 8 0 0013.771 2.2a8.015 8.015 0 00-7.446 2.169l-4.64 4.36A1 1 0 01.315 7.27l4.618-4.338a10 10 0 0116.5 3.733zm.882 4.605a1 1 0 011.37 1.458l-4.618 4.338a10 10 0 01-16.5-3.733 1 1 0 011.886-.668 8 8 0 005.776 5.134 8.015 8.015 0 007.446-2.169l4.64-4.36z"
        fill="#077AC0"
      />
    </svg>
  )
}

export default Refresh

