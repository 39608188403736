
export const isDesktop = () => {
  const mq1 = window.matchMedia('(min-width: 640px)');
  const mq2 = window.matchMedia('(min-height: 640px) and (min-width: 1024px)')
  return mq1.matches && mq2.matches;
}

export const isTrueMobile = () => {
  return /Mobi|Android/i.test(navigator.userAgent) || window.matchMedia("(any-hover: none) and (max-width: 640px)").matches;
}

export default isDesktop;
