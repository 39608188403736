import * as React from "react"

function PhoneIcon(props) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 18.98v-2.996a3.012 3.012 0 00-2.594-3.041 11.944 11.944 0 01-2.613-.649 3.018 3.018 0 00-3.18.678l-.724.724A15.064 15.064 0 018.28 9.094L9 8.375a3.008 3.008 0 00.683-3.18c-.313-.837-.53-1.709-.648-2.597A3.016 3.016 0 006.02 0H3.013C2.166 0 1.36.357.788.98a3.007 3.007 0 00-.776 2.301 20.85 20.85 0 003.243 9.154 20.577 20.577 0 006.335 6.321 20.909 20.909 0 009.105 3.231 3.028 3.028 0 002.328-.778c.625-.573.98-1.382.976-2.23zm-2.866-4.049c.497.07.868.508.855 1.042v3.01a1.005 1.005 0 01-1.095 1.008 18.89 18.89 0 01-8.218-2.923 18.588 18.588 0 01-5.729-5.717 18.841 18.841 0 01-2.934-8.266 1.006 1.006 0 011.002-1.078h3.014c.514-.005.944.364 1.015.863.136 1.029.39 2.048.757 3.03.139.368.05.782-.226 1.06L6.303 8.23c-.32.32-.387.813-.163 1.206a17.072 17.072 0 006.407 6.396c.394.223.888.157 1.208-.163l1.276-1.274c.275-.271.69-.36 1.057-.222.985.367 2.006.621 3.046.758z"
        fill="#077AC0"
      />
    </svg>
  )
}

export default PhoneIcon;

