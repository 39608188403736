import { useWebChat } from "../../context";
import PhoneIcon from "../../icons/Phone";
import Refresh from "../../icons/Refresh";
import HollowStar from "../../icons/HollowStar";
import locales from '../../locales';
import { StyledMenuButton } from '../../styledComponents';
import GlobeIcon from "../../icons/GlobeIcon";
import { datadogLogs } from "@datadog/browser-logs";

const MainMenu = () => {
  const {
    closeMenu,
    reduxStore,
    setReset,
    setSpinnerDisplayed,
    setInteracted,
    fetchToken,
    lang,
    setLang,
    startFeedback,
    setIsHandover,
    isHandover,
    handleEndHandOver,
  } = useWebChat();

  const handleCallBlueCross = async () => {
    const { current } = reduxStore;

    current.dispatch({
      type: 'WEB_CHAT/SEND_EVENT',
      payload: {
        name: `resetconversation`,
      }
    });

    await new Promise(r => setTimeout(r, 50));

    current.dispatch({
      type: 'WEB_CHAT/SEND_EVENT',
      payload: {
        name: `phonehome`,
      }
    });

    closeMenu();
  }

  const handleRefresh = async () => {
    setReset(true);
    setSpinnerDisplayed(true);
    await fetchToken();
    setReset(false);
    setInteracted(false);
    setSpinnerDisplayed(false);
    closeMenu();
  }

  const handleLanguageChange = async () => {
    const newLang = lang === 'fr-ca' ? 'en-us' : 'fr-ca';
    setLang(newLang);
    handleRefresh();
  }

  return (
    <ul>
      <li>
        {isHandover &&
          <StyledMenuButton onClick={() => {
            handleEndHandOver(true)
            closeMenu();
          }}>
            <PhoneIcon />
            <span>{locales(lang, 'closeLiveChat')}</span>
          </StyledMenuButton>
        }
      </li>
      <li>
        <StyledMenuButton disabled={isHandover} onClick={handleRefresh}>
          <Refresh />
          <span>{locales(lang, 'reloadCTA')}</span>
        </StyledMenuButton>
      </li>
      <li>
        <StyledMenuButton disabled={isHandover} onClick={handleCallBlueCross}>
          <PhoneIcon />
          <span>{locales(lang, 'callCTA')}</span>
        </StyledMenuButton>
      </li>
      <li>
        <StyledMenuButton disabled={isHandover} onClick={handleLanguageChange}>
          <GlobeIcon />
          <span>{locales(lang, 'languageCTA')}</span>
        </StyledMenuButton>
      </li>
      <li>
        <StyledMenuButton disabled={isHandover} onClick={() => startFeedback('header')}>
          <HollowStar width={22} height={21} fill="#077AC0" />
          <span>{locales(lang, 'feedbackCTA')}</span>
        </StyledMenuButton>
      </li>
    </ul>
  );
};

export default MainMenu;
