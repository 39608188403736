import { useCallback, useState } from 'react';
import { useWebChat } from "../../context";
import locales from '../../locales';
import { StyledMenuButton, StyledAltMenuButton } from '../../styledComponents';
import { StyledFeedbackFormContainer, StyledForm } from './Styled';
import FeedbackFormMessage from './FeedbackFormMessage';
import { useFormContext } from "react-hook-form";
import FeedbackSlider from './FeedbackSlider';

const FeedbackForm = ({isFormSubmitted}) => {
  const { formState: { isSubmitted, isSubmitting, isValid, errors }, watch, setError } = useFormContext();
  const [fieldError, setFieldError] = useState()

  const [formStep, setFormStep] = useState(0);

  const {
    closeMenu,
    lang,
    handleSkipAndEnd,
    feedbackFrom,
  } = useWebChat();

  const checkForErrorBeforeSubmit = useCallback(() => {
    const recommendRating = watch('recommendRating')

    const error = {}

    if (recommendRating == null) {
      error.recommendRating = 'required'
    }

    setFieldError(error)
  }, [watch, setFieldError])

  const handleNextStep = useCallback(() => {
    const score = watch('rating')
    const comment = watch('comment')

    const error = {}

    if (score == null) {
      error.rating = 'required'
    }

    if (comment == null || comment === "") {
      error.comment = 'required'
    }

    setFieldError(error)

    if (score == null || comment == null || comment === "") {
      return
    }

    setFormStep(1)
  }, [setFormStep, watch, setFieldError])

  return (
    <StyledFeedbackFormContainer>
      {!(isSubmitting || (!isValid && !isSubmitted)) &&
        <>
          <li>
            <StyledForm>
              {formStep === 0 && 
                <FeedbackSlider
                  error={fieldError}
                  sliderInputName='rating' 
                  sliderLabelName='rateFeedbackLabel' 
                  commentInputName='comment' 
                  commentLabelName='rateFeedbackCommentLabel'
                  commentQuestion="rateVirtual"
                  rateMinLabel='feedbackRate0'
                  rateMaxLabel='feedbackRate7'
                  customRateLabel
                  min={1}
                  max={7}
                />
              }

              {formStep === 1 && (
                <FeedbackSlider 
                  error={fieldError}
                  commentQuestion="rateMotivation"
                  sliderInputName='recommendRating' 
                  sliderLabelName='rateRecommendToFriend' 
                  commentInputName='recommendText' 
                  commentLabelName='otherCommentsFeedback'
                  rateMinLabel='rate0'
                  rateMaxLabel='rate10'
                />
              )}
            </StyledForm>
          </li>

          {formStep === 0 &&
            <>
              <li>
                <StyledMenuButton type="button" onClick={handleNextStep} justifyContent="center" paddingX="0">
                  <span>{locales(lang, 'confirmNextCTA')}</span>
                </StyledMenuButton>
              </li>
              <li>
                <StyledAltMenuButton type="button" onClick={() => setFormStep(1)} justifyContent="center">
                  <span>{locales(lang, 'skip')}</span>
                </StyledAltMenuButton>
              </li>
            </>
          }

          {formStep === 1 &&
            <>
              <li>
                <StyledMenuButton type="submit" onClick={checkForErrorBeforeSubmit} justifyContent="center" paddingX="0">
                  <span>{locales(lang, 'submitCTA')}</span>
                </StyledMenuButton>
              </li>

              <li>
                <StyledAltMenuButton onClick={feedbackFrom === 'end' ? handleSkipAndEnd : closeMenu} justifyContent="center">
                  <span>{locales(lang, 'skipAndEndCTA')}</span>
                </StyledAltMenuButton>
              </li>
            </>
          }
        </>
      }

      {isFormSubmitted &&
        <FeedbackFormMessage>
          <p>{locales(lang, 'feedbackFormConfirmation')}</p>
        </FeedbackFormMessage>
      }

    </StyledFeedbackFormContainer >
  );
};

export default FeedbackForm;
