import { useWebChat } from "../../context";
import locales from "../../locales";
import { StyledTitle, StyledList, ButtonCateg, StyledCategName, StyledCategRadio } from "./Styled";

const Category = ({ data, setCateg, knowledgebase, activeKnowledgebase, activeCategory }) => {
  const { lang } = useWebChat();
  return (
    <>
      {activeKnowledgebase === knowledgebase && (
        <>
          <StyledTitle><span>{locales(lang, 'situation')}</span></StyledTitle>
          <StyledList>
            {data.situations.filter(() => activeKnowledgebase === knowledgebase).map(({ situation_name, situation_id }) => (
              <li key={situation_id}>
                <ButtonCateg htmlFor={situation_id} className={activeCategory === situation_id ? 'active' : ''} onClick={() => setCateg(situation_id)}>
                  <StyledCategName>
                    {situation_name}
                  </StyledCategName>
                  <StyledCategRadio id={situation_id} defaultChecked={activeCategory === situation_id} type="radio" name="situations" />
                </ButtonCateg>
              </li>
            ))}
          </StyledList>
        </>
      )}
    </>
  )
}

export default Category;
