import styled from "@emotion/styled";
import { useWebChat } from "../../context";
import AssuranceSanteIcon from "../../icons/AssuranceSanteIcon";
import AssuranceVoyageIcon from "../../icons/AssuranceVoyageIcon";
import BlankIcon from "../../icons/BlankIcon";
import locales from "../../locales";
import { MainTitle, StyledListMargin, StyledItem, FaqButton, KbNameSpan, StatusBar } from "./Styled";


const SpanIcon = styled.span`
    .desktop-faq-only & {
      @media screen and (min-width: 1024px) {
        & > svg {
          width: 3.125em;
          height: 3.125em;
        }
      }
    }
  `;

const Knowledgebase = ({ qnamaker, setKb, activeKnowledgebase }) => {
  const { lang } = useWebChat()
  const getIcon = (kb) => {
    switch (kb) {
      case 'Croix Bleue Chatbot(composer)':
      case 'croix-bleue-chatbot-relance(composer)':
      case 'chatbot-relance-voyage(composer)':
      case 'faq-only-chatbot-relance-voyage(composer)':
        return <AssuranceVoyageIcon />
      case 'health':
        return <AssuranceSanteIcon />
      default:
        return <BlankIcon />
    }
  }

  const getName = (kb) => {
    switch (kb) {
      case 'Croix Bleue Chatbot(composer)':
      case 'croix-bleue-chatbot-relance(composer)':
      case 'chatbot-relance-voyage(composer)':
      case 'faq-only-chatbot-relance-voyage(composer)':
        return locales(lang, 'assurance_voyage');
      case 'health':
        return <AssuranceSanteIcon />
      default:
        return <BlankIcon />
    }
  }

  return (
    <>
      <MainTitle>{ locales(lang, 'faq') }</MainTitle>
      <StyledListMargin>
        {qnamaker.slice(0, 2).map(({ knowledgebase, kbName, data }) => (
          <StyledItem key={knowledgebase} className={qnamaker.length === 1 ? 'one-item' : 'more-item'}>
            <FaqButton onClick={() => setKb(knowledgebase, data.situations[0]?.situation_id)}>
              <SpanIcon>
                {getIcon(knowledgebase)}
              </SpanIcon>
              <KbNameSpan >
                {getName(knowledgebase)}
              </KbNameSpan>
              <StatusBar className={activeKnowledgebase === knowledgebase ? 'active' : ''} />
            </FaqButton>
          </StyledItem>
        ))}
      </StyledListMargin>
    </>
  )
}

export default Knowledgebase;
