import axios from "axios";
import request from "./request";

const client = axios.create({
  method: 'GET',
  timeout: 30000,
})

const fetchAutoComplete = async ({ s = "", kn, url }) =>
  request(client, {
    baseURL: url,
    params: {
      s, kn
    },
  })

export default fetchAutoComplete;
