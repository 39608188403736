import styled from "@emotion/styled"
import { useEffect, useRef } from "react";
import { useWebChat } from "../../context";
import locales from "../../locales";

const QnaHeroCard = ({ next, card }) => {
  const StyledDiv = styled.div`
    .ac-container .ac-adaptiveCard {
      padding: 0.5em 1em !important;
      font-size: 14px;

      @media screen and (min-width: 640px) {
        padding: 1.063em 1.313em !important;
        font-size: 18px;
      }
    }

    .ac-textBlock,
    .ac-container,
    .ac-container p {
      font-size: 14px !important;

      @media screen and (min-width: 640px) {
        font-size: 18px !important;
      }
    }

    & + .webchat__adaptive-card-renderer .ac-actionSet {
        display: flex;
        flex-direction: row !important;
        align-items: stretch;
        flex-wrap: nowrap;
        position: absolute;
        bottom: -34px;
        right: -24px;
        gap: 0 2px;
    }

    & + .webchat__adaptive-card-renderer .ac-pushButton {
        display: flex;
        justify-content: center;
        margin: 0 !important;
        width: 45px !important;
        height: 45px !important;
    }

    & + .webchat__adaptive-card-renderer .ac-pushButton[aria-pressed="true"] ~ .extraText {
      display: none !important;
    }

    & + .webchat__adaptive-card-renderer .ac-pushButton > div {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
    }

    & + .webchat__adaptive-card-renderer .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `;

  const { lang } = useWebChat()
  const ref = useRef(null)

  useEffect(() => {
    if (card.attachment?.content?.buttons == null) {
      return;
    }

    const buttons = Array.from(ref.current?.nextElementSibling?.querySelectorAll('.ac-pushButton'))

    if (buttons == null || buttons.length === 0) {
      return;
    }

    const isDisabled = buttons.find(btn => btn.getAttribute('aria-pressed') === 'true')

    if (isDisabled) {
      buttons.forEach(btn => { btn.setAttribute('disabled', true) })
      return
    }

    if (ref.current?.nextElementSibling?.querySelector('.custom-btn') != null) {
      return
    }

    const container = ref.current?.nextElementSibling?.querySelector('.ac-actionSet')

    if (container != null) {
      if (container.querySelector('.extraText') != null) {
        return
      }
      container.insertAdjacentHTML('beforeend', `<div style="display: flex; align-items: center; order: -1; font-size: 12px; margin-right: 16px" class="extraText">${locales(lang, 'wasUseful')}</div>`)
    }

    buttons.forEach((btn, i) => {
      if (i === 0) {
        btn.innerHTML = `
          <span class="icon-container custom-btn">
            <svg width="24" class="icon-normal"  height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.922 8h-4.717V5c0-2.21-1.817-4-4.059-4-.4 0-.764.233-.927.594L6.43 10H4.043C2.363 10 1 11.343 1 13v7c0 1.657 1.363 3 3.044 3h14.489c1.506.017 2.805-1.071 3.032-2.55l1.4-8.998a2.97 2.97 0 0 0-.712-2.42A3.063 3.063 0 0 0 19.922 8ZM8.102 21h10.442a1.01 1.01 0 0 0 1.015-.852l1.4-8.998a.99.99 0 0 0-.237-.806 1.028 1.028 0 0 0-.789-.344H14.19c-.56 0-1.014-.448-1.014-1V5c0-.893-.594-1.649-1.413-1.906l-3.66 8.118V21Zm-2.029-9v9h-2.03c-.56 0-1.014-.448-1.014-1v-7c0-.552.455-1 1.015-1h2.03Z" fill="#077AC0"/></svg>
            <svg width="24" class="icon-hover" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.205 8h4.717a3.063 3.063 0 0 1 2.331 1.031 2.97 2.97 0 0 1 .712 2.42l-1.4 8.999c-.227 1.479-1.526 2.567-3.032 2.55H7V8.733l3.22-7.14c.162-.36.525-.593.926-.593 2.242 0 4.059 1.79 4.059 4v3ZM5 10h-.956C2.363 10 1 11.343 1 13v7c0 1.657 1.363 3 3.044 3H5V10Z" fill="#077AC0"/></svg>
          </span>`
      } else {
        btn.innerHTML = `
          <span class="icon-container custom-btn">
            <svg width="24" class="icon-normal"  height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.078 16h4.717v3c0 2.21 1.817 4 4.059 4 .4 0 .764-.233.927-.594L17.57 14h2.385C21.637 14 23 12.657 23 11V4c0-1.657-1.363-3-3.044-3H5.467C3.961.983 2.662 2.071 2.435 3.55l-1.4 8.999a2.971 2.971 0 0 0 .712 2.42A3.063 3.063 0 0 0 4.078 16Zm11.82-13H5.455a1.01 1.01 0 0 0-1.014.852l-1.4 8.998a.99.99 0 0 0 .237.806c.195.222.48.347.789.344H9.81c.56 0 1.014.448 1.014 1v4c0 .893.594 1.649 1.413 1.906l3.66-8.118V3Zm2.029 9V3h2.03c.56 0 1.014.448 1.014 1v7c0 .552-.455 1-1.015 1h-2.03Z" fill="#077AC0"/></svg>
            <svg width="24" class="icon-hover" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 1H5.467C3.961.983 2.662 2.071 2.435 3.55l-1.4 8.999a2.971 2.971 0 0 0 .712 2.42A3.063 3.063 0 0 0 4.078 16h4.717v3c0 2.21 1.817 4 4.059 4 .4 0 .764-.233.927-.594L17 15.267V1ZM19 14h.956C21.637 14 23 12.657 23 11V4c0-1.657-1.363-3-3.044-3H19v13Z" fill="#077AC0"/></svg>
          </span>`
      }
    })
  })

  return (
    <StyledDiv ref={ref} className='hero-card-custom qna-hero-card' />
  )
}

export default QnaHeroCard;
