import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { useWebChat } from '../../context';
import { SrOnly, Button } from "../../styledComponents"
import Robot from '../../icons/Robot';
import locales from '../../locales';

const ToggleContainer = styled.a`
  width: 12em;
  height: 2.875em;
  background: var(--color-primary-darker);
  box-sizing: border-box;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
`;

const Pellet = styled(Button)`
  width: 6.5em;
  height: 2.875em;
  background: var(--color-white);
  border-radius: 27px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.2s ease-out;
  transform: translateX(0);
  cursor: pointer;

  &:hover {
    background-color: #E0E0E0;
  }
`;

const DynamicPellet = styled(Pellet, {
  shouldForwardProp: prop =>
    isPropValid(prop) && prop !== 'transform'
})(({ transform }) => ({
  transform,
}))

const Span = styled.span`
  font-weight: bold;
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: -0.3px;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  user-select: none;
`;

const DynamicSpan = styled(Span, {
  shouldForwardProp: prop =>
    isPropValid(prop) && prop !== 'color'
})(({ color }) => ({
  color,
}))

const SpanRobotIcon = styled(Span)`
  position: absolute;
  left: 0;
  min-width: 6.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpanFAQ = styled(DynamicSpan)`
  position: absolute;
  right: 0;
  min-width: 6.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 30px;

  &:hover {
    background-color: #084970;
  }
`;

const Toggle = () => {
  const { setTab, activeTab, setSpinnerDisplayed, lang } = useWebChat();
  return (
    <ToggleContainer
      role="button"
      onClick={() => {
        setTab(!activeTab)
        setSpinnerDisplayed(activeTab)
      }}
    >
      <Background>
        <SpanRobotIcon><Robot /></SpanRobotIcon>
        <SpanFAQ>FAQ</SpanFAQ>
      </Background>
      <DynamicPellet
        transform={activeTab ? 'translateX(0)' : 'translateX(88px)'}
      >
        {activeTab ? 
          <Span><Robot /></Span> : 
          <DynamicSpan color="var(--color-primary)">FAQ</DynamicSpan>
        }
      </DynamicPellet>
      <SrOnly>{locales(lang, 'toggleText')}</SrOnly>
    </ToggleContainer >
  )
}

export default Toggle;
