import { useWebChat } from "../../context";
import locales from '../../locales';
import { StyledMenuButton, StyledAltMenuButton } from '../../styledComponents';

const MainMenu = () => {
  const {
    closeMenu,
    lang,
    startFeedback,
    interacted,
    handleSkipAndEnd,
    isHandover,
    reduxStore,
    setIsHandover,
    handleEndHandOver,
  } = useWebChat();

  return (
    <ul>
      {isHandover && (
        <li>
          <StyledMenuButton type="button" onClick={() => {
            handleEndHandOver(true);
            closeMenu();
          }} justifyContent="center" >
            <span>{locales(lang, 'endHandover')}</span>
          </StyledMenuButton>
        </li>
      )}
      <li>
        <StyledMenuButton id="btn-end-conversation" onClick={() => {
          if (isHandover) {
            handleEndHandOver(true);
          }

          if (!interacted) {
            handleSkipAndEnd();
            return;
          }
          startFeedback('end')
        }} justifyContent="center" >
          <span>{locales(lang, 'endCTA')}</span>
        </StyledMenuButton>
      </li>
      <li>
        <StyledAltMenuButton type="button" onClick={closeMenu} justifyContent="center" >
          <span>{locales(lang, 'cancelCTA')}</span>
        </StyledAltMenuButton>
      </li>
    </ul>
  );
};

export default MainMenu;
