const AssuranceSanteIcon = (props) => {
  return (
    <svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 38c10.493 0 19-8.507 19-19S29.493 0 19 0 0 8.507 0 19s8.507 19 19 19z"
        fill="#fff"
      />
      <path
        d="M17.002 11.253c-.404-.404-1.018-.593-1.825-.562a4.653 4.653 0 00-3.118 1.366 4.655 4.655 0 00-1.368 3.121c-.031.808.158 1.423.563 1.828l1.51 1.509 5.75-5.75-1.512-1.512zM21.393 27.142c.377.376.933.566 1.658.566.055 0 .11 0 .168-.003a4.652 4.652 0 003.118-1.367 4.655 4.655 0 001.368-3.12c.031-.808-.159-1.424-.564-1.828l-1.508-1.509-5.752 5.75 1.512 1.511z"
        fill="#077AC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.218 10.69a4.653 4.653 0 013.12 1.367 4.655 4.655 0 011.367 3.121c.031.808-.158 1.423-.564 1.828L17.002 27.142c-.376.376-.933.566-1.657.566-.055 0-.11 0-.168-.003a4.653 4.653 0 01-3.118-1.367 4.655 4.655 0 01-1.368-3.12c-.031-.808.158-1.424.563-1.828l10.14-10.137c.404-.404 1.018-.593 1.825-.562zm-4.996 6.882a.46.46 0 10-.65.65.46.46 0 00.65-.65zm0 2.602a.46.46 0 10-.65.65.46.46 0 00.65-.65zm-1.302-1.301a.46.46 0 10-.65.65.46.46 0 00.65-.65zm1.952 3.252a.46.46 0 10.651-.65.46.46 0 00-.65.65zm0-2.602a.46.46 0 10.651-.65.46.46 0 00-.65.65zm0-2.602a.46.46 0 10.651-.651.46.46 0 00-.65.651zm1.302 3.903a.46.46 0 10.65-.65.46.46 0 00-.65.65zm0-2.602a.46.46 0 10.65-.65.46.46 0 00-.65.65zm1.302 1.301a.46.46 0 10.65-.651.46.46 0 00-.65.651z"
        fill="#077AC0"
      />
    </svg>
  )
}

export default AssuranceSanteIcon
