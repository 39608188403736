import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useWebChat } from "../../context";
import FeedbackForm from "./FeedbackForm";

export default function FeedbackFormContainer() {
  const methods = useForm();

  const {
    closeMenu,
    reduxStore,
    setInteracted,
    resetDiscussion,
    handleSkipAndEnd,
    feedbackFrom
  } = useWebChat();

  const [isFormSubmitted, setFormSubmit] = useState(false)

  const handleConfirmEnd = async (data) => {
    if (data.recommendRating == null) {
      methods.setError('recommendRating')
      throw 'missing field'
    }

    const { current } = reduxStore;

    current.dispatch({
      type: 'WEB_CHAT/SEND_EVENT',
      payload: {
        name: `feedback`,
        value: {
          'feedbackScore': data?.rating,
          'feedbackComment': data?.comment,
          'feedbackFrom': feedbackFrom,
          'feedbackRecommendScore': data.recommendRating,
          'feedbackRecommendComment': data.recommendText,
        },
      },
    });

    if (feedbackFrom === 'header') {
      setInteracted(true);
    }

    if (feedbackFrom === 'end') {
      setInteracted(false);
    }

    setFormSubmit(true)

    await new Promise(r => setTimeout(r, 1000));

    if (feedbackFrom === 'end') {
      resetDiscussion();
      handleSkipAndEnd();
    } else {
      closeMenu();
    }
  }

  return (
    <div style={{
      maxHeight: '93vh',
      overflow: 'auto'
    }}>
      <FormProvider {...methods} >
        <form 
          onSubmit={methods.handleSubmit(async (data) => {
            try {
              await handleConfirmEnd(data)
            } catch (e) {}
          })}
          >
          <FeedbackForm isFormSubmitted={isFormSubmitted} />
        </form>
      </FormProvider>
    </div>
  );
}
