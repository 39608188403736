import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { exitFullscreen, goFullscreen } from '../helpers/fullscreen';
import { isTrueMobile } from '../helpers/isDevice';
import fetchTokenApi from '../api/fetchToken'
import { datadogLogs } from '@datadog/browser-logs';

const WebChatContext = createContext(null);


let timeout = null;


function WebChatProvider({ children, tokenUrl, tokenMethod, lang = 'fr-ca', faqOnly = false, privacyPolicyUrl, onboarding, onboardingMobile, onboardingAnimationDelay, kn, autoCompleteKn, autoCompleteUrl }) {
  tokenUrl = tokenUrl || 'https://webchat-mockbot.azurewebsites.net/directline/token';
  tokenMethod = tokenMethod || 'POST';

  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const [isOnboardingStyle, setIsOnboardingStyle] = useState(isMobile ? onboardingMobile : onboarding);
  const [loaded, setLoaded] = useState(isOnboardingStyle);
  const [userInputText, setUserInputText] = useState()
  const [spinnerDisplayed, setSpinnerDisplayed] = useState(true);
  const [minimized, setMinimized] = useState(!isOnboardingStyle);
  const [newMessage, setNewMessage] = useState(false);
  const [token, setToken] = useState();
  const [activeTab, setTab] = useState(true);
  const [propLang, setLang] = useState(lang);
  const tokenQueryInfo = useRef({ tokenUrl, tokenMethod })
  const reduxStore = useRef(null);
  const [reset, setReset] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [endingConversation, setEndingConversation] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [feedbackFrom, setFeedbackFrom] = useState('');
  const [storageUserId, setStorageUserId] = useLocalStorage('webchat_userid', null);
  const [storageToken, setStorageToken] = useLocalStorage('webchat_token', '');
  const [interacted, setInteracted] = useLocalStorage('webchat_interaction', false);
  const [appLoaded, setAppLoaded] = useState(false);
  const [storageHideOnboarding, setStorageHideOnboarding] = useLocalStorage('webchat_hideOnboarding', 'false');
  const [onboardingAnimationEnded, setOnboardingAnimationEnded] = useState(false);
  const [visible, setVisible] = useState(true)
  const shadowRootId = useRef({ id: faqOnly ? 'shadow-host-faq' : 'shadow-host-bubble' });

  const [isHandover, setIsHandover] = useState(false)
  const [isAgentTyping, setIsAgentTyping] = useState(false)

  const handleHandover = useCallback((txt) => {
    if (isHandover) {
      const send = window.Genesys("command", "MessagingService.sendMessage", {
        message: txt
      });
      datadogLogs.logger.info("Genesys: sendMessage", { send });
    }
  }, [isHandover])

  const shouldRefetchToken = () => {
    if (!interacted) {
      return true
    }

    if (storageToken == null) {
      return true
    }

    return false
  }

  const fetchToken = async (props) => {
    const shouldRefetch = shouldRefetchToken();
    const data = await fetchTokenApi(
      { storageUserId, tokenQueryInfo: tokenQueryInfo?.current, shouldRefetch, oldToken: props?.oldToken }
    )

    const { token, userId } = data
    setToken(token)

    // session storage
    setStorageToken(token);
    setStorageUserId(userId);
    return token;
  }

  const openMenu = () => {
    setEndingConversation(false);
    setFeedback(false);
    setMenuOpen(true);
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const startEndingConversation = () => {
    setFeedback(false);
    setEndingConversation(true);
  }

  const stopEndingConversation = () => {
    setEndingConversation(false);
  }

  const startFeedback = (from = '') => {
    setFeedback(true);
    setFeedbackFrom(from)
  }

  const stopFeedback = () => {
    setFeedback(false);
  }

  const setChatbotVisibility = (bool) => {
    setVisible(bool)

    if (bool === false) {
      setMinimized(true)
    }
  }

  const handleLangChange = (lang) => {
    setLang(lang)
    datadogLogs.logger.info("Genesys: Database.update", { language: lang.slice(0, 2) });
    window.Genesys("command", "Database.update", {
      messaging:
      {
        customAttributes: {
          language: lang.slice(0, 2),
        }
      }
    });
  }

  const handleEndHandOver = (fromUser = false) => {
    if (JSON.parse(window.localStorage.getItem("genesys:handover"))?.active === true) {
      reduxStore.current.dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: `handover:off`,
        }
      });
      setIsHandover(false);
    }

    window.localStorage.removeItem("genesys:handover");

    if (fromUser) {
      datadogLogs.logger.info("Genesys: User has disconnected");
      window.Genesys("command", "MessagingService.sendMessage", {
        message: "USER: END"
      });
      window.setTimeout(() => {
        window.Genesys("command", "MessagingService.clearSession");
      }, 50)
    }
  }

  useEffect(() => {
    console.log(faqOnly)
    if (faqOnly === true) {
      return;
    }
    window.Genesys("registerPlugin", "Plugin", function (Plugin) {
      Plugin.command("GenesysJS.configuration").then((data) => {
        datadogLogs.logger.info("Genesys: configuration", data);
      });
    });

    window.Genesys("subscribe", "MessagingService.conversationDisconnected", function ({ data }) {
      datadogLogs.logger.info("Genesys: conversationDisconnected", data);
      handleEndHandOver();
    });

    window.Genesys("subscribe", "MessagingService.conversationReset", function ({ data }) {
      datadogLogs.logger.info("Genesys: conversationReset", data);
    });

    window.Genesys("subscribe", "MessagingService.error", function ({ data }) {
      datadogLogs.logger.info("Genesys: error", data);
    });

    window.Genesys("subscribe", "MessagingService.sessionCleared", function () {
      datadogLogs.logger.info("Genesys: sessionCleared");
    });

    window.Genesys("subscribe", "MessagingService.readOnlyConversation", function ({ data }) {
      datadogLogs.logger.info("Genesys: readOnlyConversation", data);
    });

    window.Genesys("subscribe", "MessagingService.started", function ({ data }) {
      datadogLogs.logger.info("Genesys: started", data);

      const handoverActive = JSON.parse(window.localStorage.getItem("genesys:handover"))?.active === true

      if (handoverActive) {
        setIsHandover(true)
        reduxStore.current.dispatch({
          type: 'WEB_CHAT/SEND_EVENT',
          payload: {
            name: `handover:on`,
          }
        });
      }
    });

    window.Genesys("subscribe", "MessagingService.typingReceived", function (a) {
      // ("typingReceived")
      setIsAgentTyping(true);
      clearTimeout(timeout);

      timeout = window.setTimeout(() => {
        setIsAgentTyping(false)
      }, 5000)
    });

    window.Genesys("subscribe", "MessagingService.messagesReceived", ({ data }) => {
      const handoverActive = JSON.parse(window.localStorage.getItem("genesys:handover"))?.active === true

      if (data.messages == null) {
        return;
      }

      if (handoverActive === false) {
        datadogLogs.logger.info("Genesys: messagesReceived but handover is not active");
        return;
      }

      datadogLogs.logger.info("Genesys: messagesReceived");

      data.messages.forEach(msg => {

        if (msg.type === "Text" && msg.direction === "Outbound") {
          reduxStore.current.dispatch({
            type: 'WEB_CHAT/SEND_MESSAGE_BACK',
            payload: {
              displayText: msg.text,
              value: "text:handoff:agent",
              text: msg.text,
              from: "agent"
            }
          });
          return;
        }

        if (msg.type === "Structured" && msg.direction === "Outbound") {
          reduxStore.current.dispatch({
            type: 'WEB_CHAT/SEND_MESSAGE_BACK',
            payload: {
              displayText: msg.text,
              value: "text:handoff:agent",
              text: msg.text,
              from: "agent"
            }
          });

          window.setTimeout(() => {
            reduxStore.current.dispatch({
              type: 'WEB_CHAT/SET_SUGGESTED_ACTIONS',
              payload: {
                suggestedActions: msg?.content.map(m => ({
                  type: 'messageBack',
                  title: m.quickReply.text,
                  text: m.quickReply.text,
                  displayText: m.quickReply.text,
                  value: "text:handoff:user"
                }))
              }
            });
          }, 500)
        }
      });
    });
  }, [faqOnly, setInteracted, setIsHandover])

  const setChatbotLanguage = (lang) => {
    if (lang === 'fr' || lang === 'fr-ca') {
      handleLangChange('fr-ca');
      return;
    }

    if (lang === 'en' || lang === 'en-us' || lang === 'en-ca') {
      handleLangChange('en-us');
    }
    datadogLogs.logger.info("Genesys: Database.update", { language: propLang.slice(0, 2) });
    window.Genesys("command", "Database.update", {
      messaging:
      {
        customAttributes: {
          language: propLang.slice(0, 2),
        }
      }
    });
  }

  const handleMaximizeButtonClick = useCallback(async () => {
    setLoaded(true);
    setMinimized(false);
    setNewMessage(false);
    setIsOnboardingStyle(false);
    setStorageHideOnboarding('true');

    window.dataLayer?.push({
      'event': 'click',
      'eventName': 'open_chatbot',
      'eventType': 'chatbot'
    })

    if (isTrueMobile()) {
      const element = document.querySelector(`#${shadowRootId.current.id}`)
      goFullscreen(element);
    }

  }, [setMinimized, setNewMessage, setStorageHideOnboarding]);

  const handleMinimizeButtonClick = useCallback(() => {
    setMinimized(true);
    setNewMessage(false);
    setIsOnboardingStyle(false);

    if (isTrueMobile()) {
      exitFullscreen();
    }

  }, [setMinimized, setNewMessage, setIsOnboardingStyle]);

  const handleCloseButtonClick = useCallback(() => {
    setMenuOpen(true);
    setEndingConversation(true);
    setFeedback(false);
  }, [setMenuOpen, setEndingConversation, setFeedback]);

  const resetDiscussion = async () => {
    setReset(true);
    setSpinnerDisplayed(true);
    await fetchToken();
    setReset(false);
    setSpinnerDisplayed(false);
  }

  const handleSkipAndEnd = () => {
    resetDiscussion();
    closeMenu();
    handleMinimizeButtonClick();
    setInteracted(false);
  }

  const [showAutocomplete, setShowAutocomplete] = useState(false)

  window.CB_CHATBOT = {
    setChatbotVisibility,
    setMinimized,
    minimized,
    visible,
    isChatTab: activeTab,
    displayChatTab: setTab,
    language: propLang,
    setChatbotLanguage,
  }

  return (
    <WebChatContext.Provider value={{
      ...{
        loaded,
        setLoaded,
        setSpinnerDisplayed,
        spinnerDisplayed,
        minimized,
        setMinimized,
        newMessage,
        setNewMessage,
        token,
        setToken,
        handleMaximizeButtonClick,
        handleMinimizeButtonClick,
        privacyPolicyUrl,
        activeTab,
        setTab,
        tokenQueryInfo,
        shadowRootId,
        'lang': propLang,
        setLang: handleLangChange,
        menuOpen,
        openMenu,
        closeMenu,
        reduxStore,
        reset,
        setReset,
        fetchToken,
        isOnboardingStyle,
        setIsOnboardingStyle,
        interacted,
        setInteracted,
        appLoaded,
        setAppLoaded,
        storageHideOnboarding,
        setStorageHideOnboarding,
        onboardingAnimationDelay,
        onboardingAnimationEnded,
        setOnboardingAnimationEnded,
        handleCloseButtonClick,
        endingConversation,
        startEndingConversation,
        stopEndingConversation,
        feedback,
        startFeedback,
        stopFeedback,
        feedbackFrom,
        visible,
        setVisible,
        resetDiscussion,
        handleSkipAndEnd,
        userInputText,
        setUserInputText,
        kn,
        autoCompleteKn,
        showAutocomplete,
        setShowAutocomplete,
        autoCompleteUrl,
        isHandover,
        setIsHandover,
        handleHandover,
        handleEndHandOver,
        isAgentTyping,
        setIsAgentTyping,
      }
    }}>
      {children}
    </WebChatContext.Provider>
  );
}

function useWebChat() {
  const context = useContext(WebChatContext);

  if (context == null) {
    return;
  }

  return context;
}

export { WebChatProvider, useWebChat };
