import { useQuery } from "react-query"
import fetchAutoComplete from "../../api/fetchAutocomplete"

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

const useAutoCompleteQuery = (search, handleSuccessCallback, kn, autoCompleteUrl) => {
  return useQuery(
    ['fetchautocomplete', search, kn],
    () => fetchAutoComplete({ s: search, kn, url: autoCompleteUrl }),
    {
      enabled: Boolean(search),
      select: (res) => {
        return res.map((v) => ({ q: v.question, id: slugify(v.question) })).slice(0, 5)
      },
      onSuccess: handleSuccessCallback,
      keepPreviousData: true,
    },
  )
}

export default useAutoCompleteQuery;
