import * as React from "react"

function HomeIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.695.55a1.825 1.825 0 012.61 0L23.73 11.141a.95.95 0 010 1.327.913.913 0 01-1.306 0l-.27-.275v9.93c0 1.036-.827 1.876-1.846 1.876h-4.616v-8.442c0-1.036-.826-1.876-1.846-1.876h-3.692c-1.02 0-1.846.84-1.846 1.876V24H3.692c-1.02 0-1.846-.84-1.846-1.876v-9.93l-.27.275a.913.913 0 01-1.306 0 .949.949 0 010-1.326L10.695.549z"
        fill="#fff"
      />
      <path d="M10.154 24h3.692v-8.442h-3.692V24z" fill="#fff" />
    </svg>
  )
}

export default HomeIcon
