import { useState } from 'react';
import { useWebChat } from "../../context";
import { useFormContext } from "react-hook-form";
import locales from '../../locales';
import { ErrorMessage, ErrorMessageBox, StyledFormTextarea, StyledFormTextareaCounter } from './Styled';
import ErrorIcon from '../../icons/Error';

const FeedbackFormComments = ({ commentMaxLength = 250, inputName, labelName, error, ...rest }) => {
  const { register } = useFormContext();
  const [commentCurrentLength, setcommentCurrentLength] = useState(0);
  const { lang } = useWebChat();

  const handleCommentChange = (e) => {
    const value = e.target.value;
    setcommentCurrentLength(value.length);
  }

  return (
    <>
      <fieldset {...rest}>
        <label>
          {locales(lang, labelName)}
        </label>
        <StyledFormTextarea
          {...register(inputName)}
          placeholder={locales(lang, labelName)}
          onChange={e => handleCommentChange(e)}
          maxLength={commentMaxLength}
        />
        <StyledFormTextareaCounter>
          {commentCurrentLength}/{commentMaxLength}
        </StyledFormTextareaCounter>
      </fieldset>
      {commentCurrentLength === 0 && error?.[inputName] && <ErrorMessageBox><ErrorMessage><ErrorIcon />{locales(lang, 'errorRequired')}</ErrorMessage></ErrorMessageBox>}
    </>
  );
};

export default FeedbackFormComments;
