import * as React from "react"

function HollowStar({width=30, height=29, fill = "#F2C94C", ...rest}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8044 1.07576L9.99473 8.78638L1.47509 10.0305C0.381974 10.1901 -0.0536937 11.5325 0.737686 12.3025L6.90136 18.3002L5.44672 26.7733C5.25991 27.8615 6.4032 28.6912 7.38127 28.1773L14.9999 24.1746L22.6186 28.1773C23.5966 28.6912 24.7399 27.8615 24.5531 26.7733L23.0985 18.3002L29.2621 12.3025C30.0535 11.5325 29.6179 10.1901 28.5247 10.0305L20.0051 8.78638L16.1954 1.07576C15.7063 0.0859132 14.2935 0.0859132 13.8044 1.07576ZM12.0758 10.5927L14.9999 4.67444L17.924 10.5927C18.118 10.9854 18.4929 11.2577 18.9267 11.321L25.4676 12.2762L20.7357 16.8807C20.4212 17.1867 20.2777 17.6277 20.3519 18.06L21.4683 24.5633L15.6204 21.4909C15.232 21.2868 14.7678 21.2868 14.3794 21.4909L8.53144 24.5633L9.6479 18.06C9.72211 17.6277 9.57857 17.1867 9.2641 16.8807L4.53215 12.2762L11.0731 11.321C11.5069 11.2577 11.8818 10.9854 12.0758 10.5927Z"
        fill={fill}
      />
    </svg>
  )
}

export default HollowStar