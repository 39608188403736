import { StyledFormMessage } from './Styled';

const FeedbackFormMessage = ({ children }) => {
  return (
    <StyledFormMessage>
      {children}
    </StyledFormMessage>
  );
};

export default FeedbackFormMessage;
