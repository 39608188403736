import styled from '@emotion/styled';
import { useWebChat } from '../../context';
import locales from '../../locales';

const ChatFooter = styled.footer`
  height: var(--chat-footer-height);
  background: var(--color-primary-dark);
  color: var(--color-white);
  display: flex;
  justify-content: flex-end;
  align-items:center;
  flex-shrink: 0;
  z-index: 2;
`;

const SmallText = styled.small`
  padding-bottom: 1em;
  padding-top: 1.125em;
  padding-right: 1.5em;
  font-size: 0.75em;
  color: var(--color-white) !important;
  text-decoration: underline; 
  font-weight: normal !important;

  &:hover {
    color: #E0E0E0 !important;
  }
`;

const Footer = () => {
  const { lang, privacyPolicyUrl } = useWebChat();

  return (
    <ChatFooter className="chat-box-footer">
      <a target="_blank" href={privacyPolicyUrl} rel="noreferrer">
        <SmallText className="text-small">
          {locales(lang, 'privacy')}
        </SmallText>
      </a>
    </ChatFooter >
  )
}



export default Footer;
