import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./genesys";
import "./datadog";

const rootElements = Array.from(document.getElementsByClassName('croixbleueapp'));

const style = `

  body.cb-chat-open-modal {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  .cb-chat-open-modal .web-chat-app .chat-box {
    -webkit-overflow-scrolling: touch;
  }
`;

const getLang = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('lang') ?? (document.documentElement.lang || 'fr');
  }

  return document.documentElement.lang || 'fr';
}

rootElements.forEach((rootElement) => {
  // tokenUrl should be with the full path https://domain.tld/api/config
  const tokenUrl = rootElement.dataset.tokenUrl || '';

  // should be GET or POST
  const tokenMethod = rootElement.dataset.tokenMethod || '';

  // qnaUrl should be only the domain, ie: https://domain.tld, /api/qna will be appended
  const qnaUrl = rootElement.dataset.qnaUrl || '';

  // should be true if you want faq
  const faqOnly = rootElement.dataset.faqOnly === 'true' || '';

  // privacy link can change dependings on the website where the chatbot is called
  const privacyPolicyUrl = rootElement.dataset.privacyPolicyUrl;

  const autoCompleteUrl = rootElement.dataset.autocompleteUrl

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log('DEV!')
  } else {
    // production code
  }


  const lang = getLang();
  const faqLang = lang.includes('en') ? 'en-us' : 'fr-ca';

  const faqkn = rootElement.dataset.kn || '';
  const autoCompleteKn = rootElement.dataset.knAutocomplete || '';

  // if onboarding="true", app should load it
  // @var bool
  const onboarding = rootElement.dataset.onboarding === 'true';
  const onboardingMobile = rootElement.dataset.onboardingMobile === 'true';

  const onboardingAnimationDelay = rootElement.dataset.onboardingAnimationDelay || 5;

  ReactDOM.render(
    <React.StrictMode>
      <style>
        {style}
      </style>
      <App tokenUrl={tokenUrl} autoCompleteUrl={autoCompleteUrl} tokenMethod={tokenMethod} faqOnly={faqOnly} qnaUrl={qnaUrl} autoCompleteKn={autoCompleteKn} kn={faqkn} lang={faqLang} onboarding={onboarding} privacyPolicyUrl={privacyPolicyUrl} onboardingMobile={onboardingMobile} onboardingAnimationDelay={onboardingAnimationDelay} />
    </React.StrictMode>,
    rootElement
  );
});
