import styled from '@emotion/styled';

const StyledFeedbackFormContainer = styled.ul`
  .feedback-stars-label {
    font-weight: bold;
  }

  .textarea-comments {
    margin-top: 1em;
  }

  .textarea-comments label {
    display: none;
  }

  hr {
    max-width: 75%;
    margin: 20px auto;
    border-color: #FFFFFF;
    background-color: #FFFFFF;
  }
`;

const StyledForm = styled.div`
  fieldset {
    position: relative;
    border: 0;
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  fieldset ul {
    width: auto;
  }

  label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    font-feature-settings: 'salt' on;
    color: #FFFFFF;
    text-align: center;
    display: inline-block;

    @media screen and (min-width: 640px) {
      font-size: 16px;
    }
  }
`;

const StyledRatingWrapper = styled.div`
  flex-direction: column;
  max-width: 375px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledFormRadioList = styled.ul`
  display: flex;
  width: 75% !important;
  justify-content: space-between;
  overflow: visible;

  li {
    padding: 0;
  }
`;

const StyledFormSingleStarRadio = styled.li`
  max-width: 375px;

  input, span {
    display: none;
  }

  label {
    font-size: 16px;
    cursor: pointer;
  }
`;

const StyledFormTextarea = styled.textarea`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  height: 150px;
  left: 24px;
  top: 218px;
  background: #FFFFFF;
  border: 2px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 4px;
`;

const StyledFormTextareaCounter = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.5em;
`;

const StyledFormTagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    flex: none;
    flex-grow: 0;
    margin: 4px;
    padding: 0;
    max-width: 100%;

    label {
      font-size: 16px;
    }
  }

  li input[type="checkbox"] + label {
    display: flex;
    align-items: center;
    border-radius: 80px;
    background-color: transparent;
    border: 2px solid white;
    padding: 8px;
    cursor: pointer;
    font-weight: bold;
  }

  li input[type="checkbox"] + label svg {
    padding-right: .5em;
  }

  li input[type="checkbox"] {
    display: none;
  }

  li input[type="checkbox"]:checked + label {
    background-color: #FFFFFF !important;
    color: var(--color-primary-dark) !important;
  }
`;

const StyledFormMessage = styled.div`
  padding: 5em 1.5em;
  text-align: center;
  font-weight: bold;
  color: white;
`;

const UnstyledButton = styled.button`
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
`;

const ErrorMessageBox = styled.div`
  background: #FCECEB;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
  border-radius: 4px;
`;

const ErrorMessage = styled.span`
  color: #8E0900;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 0.5rem;
  display: flex;
  align-items: center;

  & > svg {
    transform: scale(0.75);
    padding-left: 0.25em;
    padding-right: 0.5em;
  }
`;

export { StyledFeedbackFormContainer, StyledForm, StyledFormRadioList, StyledFormSingleStarRadio, StyledFormTextarea, StyledFormTextareaCounter, StyledFormTagList, StyledFormMessage, UnstyledButton, StyledRatingWrapper, ErrorMessage, ErrorMessageBox };
