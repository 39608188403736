import axios from "axios";

const fetchToken = async ({ tokenQueryInfo, storageUserId, shouldRefetch, oldToken = null }) => {
  const { tokenUrl, tokenMethod } = tokenQueryInfo;
  const url = storageUserId ? tokenUrl + '?userid=' + encodeURIComponent(storageUserId) : tokenUrl;
  const bodyObject = tokenMethod?.toUpperCase() === 'POST' && !shouldRefetch ? { token: oldToken } : null;
  const bodyExpand = bodyObject ? { data: bodyObject } : {};

  const res = await axios(url, {
    method: tokenMethod,
    headers: {
      'Content-Type': 'application/json',
    },
    ...bodyExpand
  });

  return res.data;
}

export default fetchToken;
