import React, { useState } from 'react';
import root from 'react-shadow';
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useWebChat } from '../context';

// Define custom location to insert Emotion styles (instead of document head)
// From: https://emotion.sh/docs/cache-provider


const styles = `
  :host,
  * {
    font-size: 16px;
  }
`

const ShadowDomContainer = ({ children }) => {
  const { shadowRootId } = useWebChat()
  const [emotionCache, setEmotionCache] = useState(null);

  function setEmotionStyles(ref) {
    if (ref && !emotionCache) {
      const createdEmotionWithRef = createCache({
        key: 'react-faq',
        container: ref,
        speedy: false,
      });
      setEmotionCache(createdEmotionWithRef);
    }
  }

  function setShadowRefs(ref) {
    setEmotionStyles(ref);
  }

  return (
    <root.div id={shadowRootId.current.id} style={{ overflow: "auto", height: '100%', position: 'relative' }}>
      <style>{styles}</style>
      <div className="shadow-style-container" ref={setShadowRefs} />
      {emotionCache && (
        <EmotionCacheProvider value={emotionCache}>
          {children}
        </EmotionCacheProvider>
      )}
    </root.div>
  );
};

export default ShadowDomContainer;
