import { datadogLogs } from "@datadog/browser-logs";

(function (g, e, n, es, ys) {
  g['_genesysJs'] = e;
  g[e] = g[e] || function () {
    (g[e].q = g[e].q || []).push(arguments)
  };
  g[e].t = 1 * new Date();
  g[e].c = es;
  ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
})(window, 'Genesys', 'https://apps.cac1.pure.cloud/genesys-bootstrap/genesys.min.js', {
  environment: 'cac1',
  deploymentId: '75d1852e-748b-4a82-8c98-417b16eef470'
});

window.Genesys("subscribe", "MessagingService.ready", function () {
  const lang = document.documentElement.lang;
  datadogLogs.logger.info("Genesys: Database.set initial", { language: lang.slice(0, 2) });
  window.Genesys("command", "Database.set", {
    messaging:
    {
      customAttributes: {
        language: lang.slice(0, 2),
      }
    }
  });
});
