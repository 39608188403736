import { useWebChat } from "../../context";
import MinimizeIcon from "../../icons/MinimizeIcon";
import { SrOnly } from "../../styledComponents";
import { ChatHeader, ButtonsContainer, ButtonIcon, ButtonHome } from './styled';
import Toggle from '../Toggle';
import locales from "../../locales";
import Popup from "../Popup";
import HomeIcon from "../../icons/Home";
import CloseIcon from "../../icons/CloseIcon";
import styled from "@emotion/styled";
import AvatarAgent from "../../icons/AvatarAgent";

const StyledDiv = styled.section`
  position: absolute;
  left: 0;
  right: 0;
  top: var(--chat-header-height);
  background-color: #04334f;
  z-index: 10;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #FFFFFF;
  height: 52px;
  display: flex;
  align-items: center;
  padding-left: 16px;
`

const AvatarContainer = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = () => {
  const { handleMinimizeButtonClick, handleCloseButtonClick, lang, openMenu, isHandover } = useWebChat();

  return (
    <>
      <Popup />
      <ChatHeader>
        <ButtonHome onClick={openMenu}>
          <HomeIcon />
          <SrOnly>Open menu</SrOnly>
        </ButtonHome>
        <Toggle />
        <ButtonsContainer>
          <ButtonIcon id="btn-minimize-chat" onClick={handleMinimizeButtonClick}>
            <MinimizeIcon />
            <SrOnly>{locales(lang, 'collapseChat')}</SrOnly>
          </ButtonIcon>
          <ButtonIcon id="btn-close-chat" onClick={handleCloseButtonClick}>
            <CloseIcon />
            <SrOnly>{locales(lang, 'closeChat')}</SrOnly>
          </ButtonIcon>
        </ButtonsContainer>

      </ChatHeader>

      {isHandover &&
        <StyledDiv>
          {locales(lang, 'representative')}
        </StyledDiv>}
    </>
  )
}

export default Header;
