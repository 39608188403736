import * as React from 'react';
import Slider from 'rc-slider';
import raw from 'raw.macro';
import styled from '@emotion/styled';
import { useState } from 'react';
const css = raw("rc-slider/assets/index.css");

const StyledSliderContainer = styled.div`
  ${css.replace(':first-child', ':first-of-type')}

  overflow: visible;
  width: 99%;
  margin: auto;

  & .rc-slider-track {
    background-color: #04334F !important;
  }

  & .rc-slider-step,
  & .rc-slider-rail,
  & .rc-slider-track {
    height: 8px;
  }

  & .rc-slider-handle {
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
    background-color: #04334F;
    border-color: none;
    padding: 0;
    height: 8px;
    margin: 0;
    border: none;
    border-radius: 0px 3px 3px 0px;
    box-shadow: none !important;
    width: 6px;

    &:after {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.2px;
      position: relative;
      min-width: 52px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      color: white;
      background-image: url("data:image/svg+xml,%3Csvg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M37.3229 42.9276C35.1152 45.258 32.7562 47.3811 30.3965 49.26C29.5693 49.9187 28.7997 50.5009 28.1064 51.0016C27.684 51.3067 27.3761 51.5199 27.2018 51.6361C26.474 52.1213 25.5259 52.1213 24.7981 51.6361C24.6238 51.5199 24.316 51.3067 23.8935 51.0016C23.2003 50.5009 22.4306 49.9187 21.6034 49.26C19.2438 47.3811 16.8848 45.258 14.6771 42.9276C8.23852 36.1313 4.33331 28.9718 4.33331 21.6667C4.33331 9.7005 14.0338 0 26 0C37.9662 0 47.6666 9.7005 47.6666 21.6667C47.6666 28.9718 43.7614 36.1313 37.3229 42.9276Z' fill='%2304334F'/%3E%3C/svg%3E%0A");
      
    }
  }

  & .rc-slider:focus .rc-slider-handle:after,  & .rc-slider-handle:focus:after {
    min-width: 47px;
    height: 52px;
    background-image: url("data:image/svg+xml,%3Csvg width='47' height='56' viewBox='0 0 47 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M28.0632 51.26C30.4229 49.3811 32.7818 47.258 34.9896 44.9276C41.4281 38.1313 45.3333 30.9718 45.3333 23.6667C45.3333 11.7005 35.6328 2 23.6667 2C11.7005 2 2 11.7005 2 23.6667C2 30.9718 5.9052 38.1313 12.3438 44.9276C14.5515 47.258 16.9105 49.3811 19.2701 51.26C20.0973 51.9187 20.867 52.5009 21.5602 53.0016C21.9827 53.3067 22.2905 53.5199 22.4648 53.6361C23.1926 54.1213 24.1407 54.1213 24.8685 53.6361C25.0428 53.5199 25.3507 53.3067 25.7731 53.0016C26.4664 52.5009 27.236 51.9187 28.0632 51.26Z' fill='%2304334F' stroke='%23077AC0' stroke-width='3'/%3E%3C/svg%3E%0A");
  }
`

const StyledSlider = styled.div(props => ({
  '.rc-slider-handle:after': {
    content: `"${props.value}"`,
  }
}))

const TooltipSlider = ({callback, defaultValue, ...props}) => {
  const [dirty, setDirty] = useState(false)

  const handleChange = (val) => {
    callback?.(val)
    setDirty(true)
  }
  
  const handleRender = (node, sliderProps) => {
    return <StyledSlider value={(dirty || defaultValue != null) ? sliderProps.value : '-'}>{node}</StyledSlider>
  }

  return (
    <StyledSliderContainer>
      <Slider handleRender={handleRender} defaultValue={defaultValue} onChange={handleChange} {...props} />
    </StyledSliderContainer>
  );
};

export default TooltipSlider;
