import { useWebChat } from "../../context";
import MainMenu from "../Menu/MainMenu";
import ClosingMenu from "../Menu/ClosingMenu";
import FeedbackForm from "../Forms/FeedbackForm";
import { Overlay, StyledNav } from '../../styledComponents';
import FeedbackFormContainer from "../Forms/FormProvider";


const Popup = () => {
  const { 
    closeMenu,
    menuOpen,
    endingConversation,
    feedback
  } = useWebChat();

  const getView = () => {
    if (feedback) {
      return <FeedbackFormContainer />
    } else if (endingConversation) {
      return <ClosingMenu />
    } else {
      return <MainMenu />
    }
  }
  return (
    <>
      <Overlay className={menuOpen ? 'menu-open' : ''} onClick={closeMenu} />
      <StyledNav className={menuOpen ? 'menu-open' : ''}>
        {getView()}
      </StyledNav>
    </>
  );
};

export default Popup;
