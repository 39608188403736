import React, { useMemo, useRef, useCallback, useEffect, useState } from 'react';
import ReactWebChat, { createDirectLine, createStyleSet, createStore } from 'botframework-webchat';
import { useWebChat } from '../../context';
import { useQuery } from 'react-query';
import updateIn from 'simple-update-in';
import styled from '@emotion/styled';
import HeroCard from './HeroCard';
import classNames from 'classnames';
import { useLocalStorage } from 'react-use';
import QnaHeroCard from './QnaHeroCard';
import locales from '../../locales';
import { datadogLogs } from '@datadog/browser-logs';

const WebChatStyled = styled.div`
  height: 100%;

  .webchat__send-box-text-box {
    padding: 0 !important;
  }

  .webchat__send-box-text-box__input {
    padding-left: 1.25em !important;
    margin-right: 0;
    background: var(--color-white) !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .web-chat:not(.is-started) .webchat__send-box__main {
    display: none;
  }

  .webchat__send-box__button {
    background: var(--color-white) !important;
    margin: 0;
  }
  .webchat__basic-transcript__scrollable {
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
  }

  .webchat__basic-transcript .webchat__basic-transcript__activity {
    padding: 0 !important;
  }

  .webchat__bubble {
    min-width: 100%;

    p {
      font-weight: normal !important;

      font-size: 14px !important;

      @media screen and (min-width: 640px) {
        font-size: 18px !important;
      }

      line-height: 1.5em !important;
      white-space: normal !important;
      word-break: initial !important;
      word-wrap: initial !important;
    }
  }

  .webchat__bubble__content > .markdown,
  .webchat__bubble__content > .plain {
    padding: 0.5em 1em !important;

    @media screen and (min-width: 640px) {
        padding: 1.063em 1.313em !important;
      }
  }

  .webchat__bubble__content > .markdown a {
    font-size: 14px !important;

    @media screen and (min-width: 640px) {
      font-size: 18px !important;
    }
  }

  .ac-anchor {
    font-size: 14px !important;

    @media screen and (min-width: 640px) {
      font-size: 18px !important;
    }
  }

  .webchat__send-box__main {
    padding-top: 1.25em;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .webchat__basic-transcript__activity--from-bot .webchat__basic-transcript__activity-box {
    margin-right: 2.375em;

    @media screen and (min-width: 640px) {
      margin-right: 4.75em;
    }
  }

  .webchat__basic-transcript__activity.webchat__basic-transcript__activity--from-bot {
      padding: 0 !important;
  }

  .webchat__basic-transcript__activity:last-of-type {
    margin-bottom: 1.5em;
  }

  .webchat__bubble--from-user .webchat__bubble__content {
    margin-right: 1.25em !important;
    margin-left: 4.875em !important;
  }

  .webchat__stacked-layout--from-user {
    .webchat__stacked-layout__avatar-gutter {
      display: none !important;
    }
  }

  .webchat__suggested-action__button {
    padding: 0.5em !important;
    transition: background-color 0.2s ease-in-out;

    font-size: 14px !important;

    @media screen and (min-width: 640px) {
      font-size: 18px !important;
    }

    &:hover {
      background-color: var(--color-primary-dark) !important;
    }
  }

  .webchat__stacked-layout__status,
  .webchat__stacked-layout__status span {
    font-size: 13px !important;
  }

  .webchat__suggested-action {
    margin: 0.5em 1.5em !important;
  }

  .webchat__stacked-layout__avatar-gutter {
    margin-left: 0.5em;
    margin-right: 9px;
  }

  .webchat__send-icon {
    display: none;
  }

  .webchat__send-box__button {
    cursor: pointer;

    &:hover {
      &:after {
        filter: brightness(0.75);
      }
    }

    &:after {
      font-size: 16px;
      transition: filter 0.2s ease-in-out;
      filter: brightness(1);
      content: '';
      display: block;
      width: 1.625em;
      height: 1.375em;
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='22' viewBox='0 0 26 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.13493 11.9431L10.8121 11L8.13493 10.0568L4.13875 8.64889L1.34112 1.37411L23.5546 11L1.34111 20.6258L4.13875 13.351L8.13493 11.9431ZM0.395809 21.0354C0.395885 21.0354 0.395961 21.0354 0.396036 21.0353L0.395809 21.0354Z' stroke='%230094D7' stroke-width='2'/%3E%3C/svg%3E%0A");
    }
  }

  span.webchat__suggested-action__text {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;

    @media screen and (min-width: 640px) {
      font-size: 18px !important;
    }

    line-height: 1.5em !important;
  }

  .webchat__suggested-action__text.webchat__suggested-actions__button-text-stacked-text-wrap {
    display: flex;
    justify-content: center;
  }

  .webchat__initialsAvatar {
    display: none;
  }

  .webchat__imageAvatar {
    max-width: 2.25em;
    max-height: 2.25em;
  }

  .ac-textBlock,
  .ac-container,
  .ac-container p {
    font-size: 14px !important;

    @media screen and (min-width: 640px) {
      font-size: 18px !important;
    }
  }

  button.ac-pushButton {
    width: 14.438em !important;
    height: auto !important;
    margin: 0.938em 0px 0px 0px !important;
    padding: 0 !important;
    background: var(--color-white);
    border: 2px solid var(--color-primary) !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    transition: background-color 0.2s ease-in-out;

    & > div {
      padding: 8px 18px 8px  18px !important;
      white-space: normal !important;
      color: var(--color-primary) !important;
      font-style: normal !important;
      font-weight: bold !important;
      font-size: 14px !important;

      @media screen and (min-width: 640px) {
        font-size: 18px !important;
        padding: 1.063em 1.313em !important;
      }

      line-height: 1.5em !important;
      letter-spacing: -0.3px !important;
      pointer-events: none;
    }

    & .icon-container {
      position: relative;
      width: 24px;
      height: 24px;
      svg {
        transition: 0.15s opacity ease-in;
        position: absolute;
      }

      svg.icon-normal {
        opacity: 1;
      }

      svg.icon-hover {
        opacity: 0;
      }
    }

    &[aria-pressed="true"],
    &[disabled="true"] {
      cursor: auto !important;
    }

    &[disabled="true"]:not([aria-pressed="true"]) {
      display: none !important;
    }

    &:hover:not(:disabled) .icon-container,
    &[aria-pressed="true"] .icon-container {
      svg.icon-normal {
        opacity: 0;
      }

      svg.icon-hover {
        opacity: 1;
      }
    }
  }

  .webchat__stacked-layout__nub-pad + span {
    margin-right: 1.5em;
  }
`;

const Chat = () => {
  const { setNewMessage, setTab, lang, setSpinnerDisplayed, shadowRootId, reduxStore, isOnboardingStyle, setIsOnboardingStyle, interacted, setInteracted, appLoaded, setAppLoaded, onboardingAnimationDelay, setOnboardingAnimationEnded, setStorageHideOnboarding, setUserInputText, setShowAutocomplete, handleHandover, setIsHandover, isAgentTyping, isHandover } = useWebChat();
  const isTelemetryInit = useRef(false);
  const animationTimeout = parseFloat(onboardingAnimationDelay);

  const [sessionToken, setStorageToken, removeStorageToken] = useLocalStorage('webchat_token', '');

  const [message, setNextMessage] = useState(null)

  useEffect(() => {
    if (message != null) {
      handleHandover(message)
    }
  }, [message, handleHandover])

  const directLine = useMemo(() => createDirectLine({
    webSocket: true,
    token: sessionToken,
    conversationStartProperties: { locale: lang }
  }), [sessionToken, lang]);

  const styleSet = useMemo(
    () =>
      createStyleSet({
        bubbleBackground: 'var(--color-primary-light)',
        bubbleFromUserBackground: 'var(--color-primary-dark)',
        bubbleBorderWidth: '0',
        bubbleBorderRadius: '4px',
        bubbleFromUserBorderRadius: '4px',
        bubbleFromUserBorderWidth: '0',
        bubbleFromUserTextColor: 'var(--color-white)',
        backgroundColor: 'var(--color-white)',
        sendBoxBackground: 'var(--color-primary-dark)',
        sendBoxButtonColorOnDisabled: '#828282',
        suggestedActionBorderRadius: '4px',
        suggestedActionTextColor: 'var(--color-white)',
        suggestedActionBackground: 'var(--color-primary)',
        suggestedActionBorderWidth: '0',
        sendBoxHeight: 56,
        sendBoxMaxHeight: 56,
        sendBoxTextColor: 'var(--color-black)',
        sendBoxBorderBottom: '3.375em',
        sendBoxBorderLeft: '1.5em',
        sendBoxBorderRight: '1.5em',
        sendBoxBorderTop: '1.25em',
        sendBoxPlaceholderColor: '#828282',
        paddingRegular: '0.25em',
        paddingWide: '0.5em',
        botAvatarInitials: '',
        cardPushButtonBackgroundColor: 'var(--color-white)',
        cardPushButtonTextColor: 'var(--color-primary)'
      }),
    []
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  const handleConverstationStart = useCallback(() => {
    setInteracted(true);
    setIsOnboardingStyle(false);
    setStorageHideOnboarding('true');
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleOnboardingAnimation = useCallback(() => {
    if (appLoaded && isOnboardingStyle) {
      const timeout = setTimeout(() => {
        const chatBoxes = document.querySelector(`#${shadowRootId.current.id}`).shadowRoot.querySelectorAll(".webchat__basic-transcript__transcript .webchat__basic-transcript__activity:not(.js-animated), .webchat__suggested-actions .webchat__suggested-actions__stack .webchat__suggested-action__button:not(.js-animated)");
        chatBoxes[0]?.classList.add('js-animated');
        [...chatBoxes]?.forEach((chatBox, index) => {
          chatBox.style.position = 'absolute';
          chatBox.onanimationstart = () => chatBox.style.position = 'relative';
          chatBox.onanimationend = () => {
            chatBoxes[index + 1]?.classList.add('js-animated');
            if (index + 1 === chatBoxes.length) {
              setOnboardingAnimationEnded(true);
            }
          };
        });
        clearTimeout(timeout);
      }, animationTimeout * 1000);
    }
  }, [appLoaded, isOnboardingStyle, shadowRootId, animationTimeout, setOnboardingAnimationEnded])

  useEffect(() => {
    handleOnboardingAnimation();
  }, [handleOnboardingAnimation])

  const store = useMemo(
    () =>
      createStore({}, ({ dispatch, getState }) => next => action => {
        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          const { activities } = getState();

          const filteredActivities = activities
            .filter(({ type }) => type === 'message')
            .filter(((activity) => activity?.channelData?.state !== 'sending'));

          const lastActivity = filteredActivities?.[filteredActivities.length - 1]
          const lastRole = lastActivity?.from?.role;
          const { from: { role: incomingRole } } = action.payload.activity;

          const shouldDisplay = lastRole !== incomingRole;

          action = updateIn(action, ['payload', 'activity', 'channelData', 'showDisplayName'],
            () => ['user', 'bot'].includes(action.payload.activity.from.role) && shouldDisplay
          )
          action = updateIn(action, ['payload', 'activity', 'from', 'name'],
            () => (action.payload.activity.from.role === 'bot' ? locales(lang, 'botName') : locales(lang, 'you'))
          )
        }

        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && action.payload.activity.type === 'event' && action.payload.activity.value === 'VIEW_FAQ') {
          setTab(false);
          setSpinnerDisplayed(true);
        }

        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && action.payload.activity.type === 'event' && action.payload.activity.value === 'START_HANDOFF') {
          const handoverInfo = JSON.parse(window.localStorage.getItem("genesys:handover"))?.started ?? null;
          const time = new Date(action.payload.activity.timestamp).getTime();

          if (time > handoverInfo) {
            const { activities, language } = getState();
            const filteredActivities = activities
              .filter(({ type }) => type === 'message')
              .filter(((activity) => activity?.channelData?.state !== 'sending'))
              .splice(-10);

            const textArchive = filteredActivities.reduce((acc, curr) => {
              if (curr?.text != null) {
                acc = `${acc}${curr?.from?.role === "bot" ? "Bot" : "Client"}: ${curr.text} \n\n`;
              }
              return acc
            }, "\n\n")

            datadogLogs.logger.info("Genesys: Database.update with history", { language: lang.slice(0, 2), textArchive });
            window.Genesys("command", "Database.update", {
              messaging: {
                customAttributes: {
                  language: language?.slice?.(0, 2),
                  Historique: textArchive
                }
              }
            });

            datadogLogs.logger.info("Genesys: will call startConversation");
            window.setTimeout(() => {
              window.Genesys("command", "MessagingService.startConversation",
                {},
                function (e) {
                  datadogLogs.logger.info("Genesys: startConversation fulfilled", e);
                  const data = {
                    started: new Date().getTime(),
                    active: true,
                  }

                  window.localStorage.setItem("genesys:handover", JSON.stringify(data))

                  setIsHandover(true)
                  dispatch({
                    type: 'WEB_CHAT/SEND_EVENT',
                    payload: {
                      name: `handover:on`,
                    }
                  });
                },
                function (e) {
                  /*rejected callback*/
                  datadogLogs.logger.info("Genesys: startConversation rejected", e);
                  if (e === "There is already an active conversation.") {
                    const data = {
                      started: new Date().getTime(),
                      active: true,
                    }
                    window.localStorage.setItem("genesys:handover", JSON.stringify(data))
                    setIsHandover(true)
                    dispatch({
                      type: 'WEB_CHAT/SEND_EVENT',
                      payload: {
                        name: `handover:on`,
                      }
                    });
                  }
                }
              );
            }, 250)
          }
        }

        if (action?.payload?.activity?.value === "text:handoff:agent" && action?.payload?.activity?.from != null) {
          action = updateIn(action, ['payload', 'activity', 'from'],
            () => ({
              "name": locales(lang, 'agentName'),
              "role": "bot",
            })
          )
        }

        if (action?.payload?.activity?.value === "text:handoff:user" && action?.payload?.activity?.from != null) {
          const send = window.Genesys("command", "MessagingService.sendMessage", {
            message: action?.payload?.activity?.text,
          });

          datadogLogs.logger.info("Genesys: sendMessage", { send });
        }

        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && action.payload.activity.type === 'event' && action.payload.activity.value === 'REQUEST_USER_LOCATION') {
          dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
              name: `userlocation`,
              value: {
                href: window.location.href,
                pathname: window.location.pathname,
                hostname: window.location.hostname,
                browserLanguage: window.navigator.language,
              },
            },
          });
        }

        if (action.type === 'WEB_CHAT/SET_SEND_BOX') {
          const search = action.payload.text;
          setUserInputText(search)
          setShowAutocomplete(true)
          // Use the text to query the Azure database and display suggestions
        }

        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && action.payload.activity.type === 'event' && action.payload.activity.value === 'HIDE_SEND_BOX') {
          document.querySelector(`#${shadowRootId.current.id}`).shadowRoot.querySelector('.webchat__send-box__main').style.display = 'none'
        }

        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && action.payload.activity.type === 'event' && action.payload.activity.value === 'DISPLAY_SEND_BOX') {
          document.querySelector(`#${shadowRootId.current.id}`).shadowRoot.querySelector('.webchat__send-box__main').style.display = 'flex'
        }

        // will need refactor for new flow
        if ((action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && action?.payload?.activity?.from?.role === 'user' && action?.payload?.activity.type === 'message') || action?.payload?.activity?.name === 'phonehome') {
          setShowAutocomplete(false)
          handleConverstationStart();
        }

        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && action?.payload?.activity?.from?.role === 'user' && action?.payload?.activity.type === 'message' && action?.payload?.activity.text != null) {
          const latestMessage = JSON.parse(window.localStorage.getItem("genesys:latest")) ?? null;
          const msgTime = new Date(action.payload.activity.timestamp).getTime();

          if (msgTime > latestMessage) {
            window.localStorage.setItem("genesys:latest", JSON.stringify(msgTime))
            setNextMessage(action?.payload?.activity.text)
          }
        }

        if (action.type === 'WEB_CHAT/SET_SUGGESTED_ACTIONS' && action.payload.suggestedActions[0]?.type === 'imBack') {

          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            const css = document.querySelectorAll('[data-emotion^="webchat--css"], [data-emotion^="react-scroll-to-bottom--css"]');

            if (css.length > 0) {
              window.requestAnimationFrame(() => {
                const shadow = document.querySelector(`#${shadowRootId.current.id}`).shadowRoot.querySelector('.shadow-style-container');
                css.forEach(x => {
                  shadow?.appendChild(x);
                });
              })
            }
          } else {

            const cssWebChat = [...document.querySelectorAll('[data-emotion^="webchat--css"]')].flatMap(({ sheet }) => [...sheet.cssRules].map(rules => rules.cssText)).join('\n');
            const cssSTB = [...document.querySelectorAll('[data-emotion^="react-scroll-to-bottom--css"]')].flatMap(({ sheet }) => [...sheet.cssRules].map(rules => rules.cssText)).join('\n');
            const css = `${cssWebChat} ${cssSTB}`;

            if (css) {
              window.requestAnimationFrame(() => {
                const currentStyle = document.querySelector(`#${shadowRootId.current.id}`).shadowRoot.querySelector('[data-css="webchat"]');
                const shadow = document.querySelector(`#${shadowRootId.current.id}`).shadowRoot.querySelector('.shadow-style-container');

                if (currentStyle == null) {
                  shadow?.insertAdjacentHTML("beforeend", `<style data-css="webchat">${cssWebChat} ${cssSTB}</style>`);
                } else if (css !== currentStyle.innerHTML) {
                  currentStyle.innerHTML = `${cssWebChat} ${cssSTB}`;
                }
              })
            }
          }

          setAppLoaded(true);
          setSpinnerDisplayed(false);
        }

        if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          if (action.payload.activity.from.role === 'bot') {
            setNewMessage(true);
          }
        } else if (action.type === 'DIRECT_LINE/UPDATE_CONNECTION_STATUS') {
          // try to catch refresh
          if (action.payload) {
            switch (action.payload.connectionStatus) {
              case 1: // success reconnection
                setStorageToken(directLine.token);
                break;

              case 3: // TOKEN_EXPIRED
              case 4: // FAILED_TO_CONNECT
                // TODO: reset directline by finding a way to call fetchToken ?
                removeStorageToken()
                break;
              default:
                // no op
                break;
            }
          }
        }
        // other action type are: WEB_CHAT/SET_NOTIFICATION with action.payload.message = failedtoconnect
        // DIRECT_LINE/CONNECT_REJECTED
        // DIRECT_LINE/DISCONNECT_PENDING with action.meta.error
        // DIRECT_LINE/DISCONNECT_FULFILLED

        return next(action);
      }),

    [setNewMessage, setTab, setSpinnerDisplayed, shadowRootId, handleConverstationStart, setAppLoaded, directLine, setStorageToken, removeStorageToken, setUserInputText, setNextMessage, setIsHandover]
  );

  reduxStore.current = store;

  const attachmentMiddleware = () => next => (...args) => {
    const [card] = args

    switch (card.attachment.contentType) {
      case 'application/vnd.microsoft.card.hero':
        if (card.attachment?.content?.meta_type === "QnaHeroCard") {
          return (
            <>
              <QnaHeroCard next={next} card={card} />
              {next(...args)}
            </>
          )
        }
        return (
          <>
            <HeroCard next={next} card={card} />
            {next(...args)}
          </>
        )

      default:
        return next(...args);
    }
  };

  const StyledName = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #828282;
    margin-left: 24px;
  `

  const StyledUserName = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #828282;
    margin-right: 24px;
    display: flex;
    justify-content: flex-end;
  `

  const activityMiddleware = () => next => (...args) => {
    const [card] = args
    const { activity: { channelData: { showDisplayName } = {}, from: { name: botName, role } } } = card;
    return (...setupArgs) => {
      const n = next(...args)

      if (!n) {
        return n
      }

      if (role === 'user') {
        return (
          <>
            {showDisplayName && <StyledUserName className="user-custom-name">{botName}</StyledUserName>}
            {n(...setupArgs)}
          </>
        )
      }

      return (
        <>
          {showDisplayName && <StyledName className="bot-custom-name">{botName}</StyledName>}
          {n(...setupArgs)}
        </>
      )
    }
  };

  const typingIndicatorMiddleware = () => next => (args) => {
    const activeTyping = Object.values(args.activeTyping);

    if (isAgentTyping && isHandover) {
      const typing = {
        'chatbot-relance-dev': {
          "at": new Date().getTime(),
          "expireAt": new Date().getTime() + 5000,
          "name": "Assistant conversationnel",
          "role": "bot"
        }
      }

      return next({
        activeTyping: typing,
        typing: typing,
        visible: true,
      })
    }

    if (!activeTyping.length) {
      return null;
    }

    const isUser = Object.values(args.typing)[0].role === 'user';

    if (isUser && isHandover) {
      // ("send typing");
      window.Genesys("command", "MessagingService.sendTyping");
    }

    return next(args)
  }

  const activityStatusMiddleware = () => next => (...args) => {
    const { sameTimestampGroup } = args;

    if (!sameTimestampGroup) {
      return null
    }

    return next(...args)
  }

  const handleTelemetry = (e) => {
    const { name } = e;
    const { conversationId } = directLine;
    const { webchat_userid } = localStorage;
    if (name === 'init' && conversationId && webchat_userid && !isTelemetryInit.current) {
      isTelemetryInit.current = true;
      window.dataLayer?.push({
        'event': name,
        'eventName': 'chatbot_conversation_start',
        'eventType': 'chatbot',
        'conversationId': conversationId,
        'userId': webchat_userid
      })
    }
  }

  return (
    <WebChatStyled>
      <ReactWebChat
        className={classNames('web-chat', interacted ? 'is-started' : '', appLoaded ? 'loaded' : '')}
        styleSet={styleSet}
        styleOptions={{
          suggestedActionLayout: 'stacked',
          suggestedActionsStackedLayoutButtonTextWrap: true,
          hideUploadButton: true,
          avatarSize: 36,
          cardPushButtonBackgroundColor: 'var(--color-primary-dark)',
          cardPushButtonTextColor: 'var(--color-white)',
        }}
        locale={lang}
        directLine={directLine}
        attachmentMiddleware={attachmentMiddleware}
        activityMiddleware={activityMiddleware}
        username='Web Chat User'
        store={store}
        onTelemetry={handleTelemetry}
        sendTypingIndicator={true}
        activityStatusMiddleware={activityStatusMiddleware}
        typingIndicatorMiddleware={typingIndicatorMiddleware}
      />
    </WebChatStyled>
  );
}

const WebChat = () => {
  const { fetchToken, reset } = useWebChat();
  const [storageToken] = useLocalStorage('webchat_token', '');

  const { isLoading, error, data } = useQuery('fetchToken', async () => {
    const token = await fetchToken({ oldToken: storageToken });
    return token;
  });

  if (error) {
    return (
      <div>
        Something went wrong...
      </div>
    )
  }

  if (isLoading || data == null) {
    return <></>
  }

  return (
    <>
      {!reset && <Chat />}
    </>
  )
};

export default WebChat;
