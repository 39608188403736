/* eslint-disable no-unused-vars */
import styled from '@emotion/styled';
import Collapse from 'rc-collapse';
import raw from "raw.macro";
import collapseMotion from './collapseMotion';
import DOMPurify from 'dompurify';
import { StyledTitle } from './Styled';
import MarkdownIt from 'markdown-it';
import replaceSpecialChar from '../../helpers/replaceSpecialChar';
import { useWebChat } from '../../context';
import locales from '../../locales';
import removeNonBreakingSpace from '../../helpers/replaceNonBreakingSpace';
const Panel = Collapse.Panel;
const css = raw("rc-collapse/assets/index.css");

const CollapseContainer = styled.div`

  ${css.replace(':first-child', ':first-of-type')}

  .rc-collapse {
    border: none !important;
    background: transparent;
  }

  .rc-collapse-item {
    background: var(--color-white);
    margin-bottom: 0.5em;
    border: 1px solid #E0E0E0 !important;
  }

  .rc-collapse-content {
    background: var(--color-white);;
    margin: 0 !important;
    padding: 0 !important;
  }

  .rc-collapse-content-box {
    margin:  0px 1.938em 2.25em 2.125em !important;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.5em;
    color: #333333 !important;
  }

  .rc-collapse-header {
    font-weight: bold;
    font-size: 1em;
    line-height: 1.5em;
    letter-spacing: -0.18px;
    color: #333333 !important;
    padding: 1.375em 5.375em 1.375em 1.938em !important;
    margin: 0;
    position: relative;

    &:after {
      font-size: 16px;
      position: absolute;
      width: 0.875em;
      height: 0.5em;
      right: 1.938em;
      content: '';
      transform: rotate(0deg);
      transition: transform 0.2s ease-in-out;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 5.58579L12.2929 0.292893C12.6834 -0.0976306 13.3166 -0.0976306 13.7071 0.292893C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292894 1.70711C-0.0976299 1.31658 -0.0976299 0.683417 0.292894 0.292892C0.683418 -0.0976317 1.31658 -0.0976316 1.70711 0.292892L7 5.58579Z' fill='%23333333'/%3E%3C/svg%3E%0A");
    }

    &[aria-expanded="true"]:after {
      transform: rotate(180deg);
    }
  }

  .desktop-faq-only & {
    .rc-collapse-item {
      @media screen and (min-width: 1024px) {
        margin-bottom: 1em;
      }
    }    

    .rc-collapse-content-box {
      @media screen and (min-width: 1024px) {
        margin-right: 15% !important;
      }
    }

    .rc-collapse-header {
      @media screen and (min-width: 1024px) {
        font-size: 1.25em;
        line-height: 1.5em !important;
        letter-spacing: -0.2px;
        padding: 1.563em 5.375em 1.625em 2em !important;
        padding-right: 22% !important;
        font-size: 1.25em;
      }      
    }
  }
`;

const StyledButtonContainer = styled.div`
  margin-top: 1.938em;
`;

const StyledFollowUpButton = styled.button`
  padding: 1.063em 1.313em;
  width: 100%;
  transition: background-color 0.15s ease-in-out;
  background: var(--color-primary);
  border-radius: 4px;
  color: var(--color-white);
  border: none !important;
  margin-top: 0.5em !important;
  text-align: left;
  cursor: pointer;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.5em;

  /* or 150% */
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  &:hover {
    background: var(--color-primary-dark);
  }
  .desktop-faq-only & {
    @media screen and (min-width: 1024px) {
      width: auto;
    }
  }
`;

const StyledAnswerDiv = styled.div`
  white-space: normal;

  p {
    font-size: 1em;
    line-height: 1.5em;
    font-feature-settings: 'salt' on;
  }

  p + p {
    padding-top: 1em;
  }
`;

const Qna = ({ qna, knowledgebase, activeCategory, setCateg, prepareScrollToQna }) => {
  const { lang } = useWebChat();
  const qnaArray = qna
    .filter(({ situation_id }) => situation_id === activeCategory)
    .map(({ question, answer, qnaId, situation_id, prompts = [] }) =>
      <Panel
        id={replaceSpecialChar(`${knowledgebase}-${situation_id}-${qnaId}`)}
        key={`qa-${knowledgebase}-${qnaId}`}
        header={question}
        headerClass="my-header-class"
        showArrow={false}
      >

        <StyledAnswerDiv dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(removeNonBreakingSpace(answer))
        }}/>

        {prompts.length > 0 &&
          <StyledButtonContainer>
            {
              prompts.map((prompt) => (
                <StyledFollowUpButton
                  key={`follow-up-${prompt.displayOrder}-${prompt.qnaId}`}
                  onClick={() => {
                    const categ = qna.find(({ qnaId }) => prompt.qnaId === qnaId)?.situation_id;

                    if (categ != null) {
                      setCateg(categ)
                      prepareScrollToQna(`${knowledgebase}-${situation_id}-${prompt.qnaId}`)
                    }
                  }}
                >
                  {prompt.displayText}
                </StyledFollowUpButton>
              ))
            }
          </StyledButtonContainer>
        }
      </Panel>
    )

  return (
    <> {qnaArray.length > 0 && (
      <>
        <StyledTitle id="questions"><span>{locales(lang, 'q&a')}</span></StyledTitle>
        <CollapseContainer>
          <Collapse
            accordion={true}
            openMotion={collapseMotion}
            defaultActiveKey={`qa-${knowledgebase}-${qna[0]?.qnaId}`}
          >
            {qnaArray}
          </Collapse>
        </CollapseContainer>
      </>
    )}
    </>
  )
}

export default Qna;
