import { useEffect, useRef, useState, useCallback } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import fetchQna from "../../api/fetchQna";
import Qna from './Qna';
import Category from './Category';
import Knowledgebase from './Knowledgebase';
import { Container } from '../../styledComponents';
import styled from "@emotion/styled";
import replaceSpecialChar from "../../helpers/replaceSpecialChar";
import { useWebChat } from "../../context";
import Spinner from "../Spinner";
import scrollTo from '../../helpers/scrollTo';

const usePref = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const FaqContainer = styled(Container)`
  padding: 0 1.5em;
  overflow-y: auto;
  background: var(--color-primary-light);
  max-width: 1424px;
  min-height: 620px;
  margin: auto;

  .desktop-faq-only & {
    @media screen and (min-width: 1024px) {
      padding: 0 3.5em;
    }
  }
`;

const FaqWrapper = styled.div`
  counter-reset: section;
  background: var(--color-primary-light);
  margin: auto;
  width: 100%;
`;

const Faqs = ({ faqOnly = false, qnaUrl = '', kn = '' }) => {
  const { setSpinnerDisplayed, shadowRootId, lang } = useWebChat();
  const [activeCategory, setCategory] = useState(null);
  const [activeKnowledgebase, setKnowledgebase] = useState(null);
  const [activeLanguage, setLanguage] = useState(null);
  const [userInteraction, setUserInteraction] = useState(false);
  const [itemToScrollTo, setItemToScrollTo] = useState({ item: null, timestamp: 0 });
  const [qnaMakerData, setQnaMakerData] = useState(false)

  const usePreviousCategory = (value) => usePref(value);
  const usePreviousKnowledgebase = (value) => usePref(value);
  const usePreviousLanguage = (value) => usePref(value);
  const usePreviousItemToScrollTo = (value) => usePref(value);

  const setKb = (kb, category) => {
    setKnowledgebase(kb);
    setCategory(category);
    setUserInteraction(true)
  }

  const setCateg = (situation_id) => {
    setCategory(situation_id)
    setUserInteraction(true)
  }

  const setCurrentLang = (lang) => {
    setLanguage(lang)
    setUserInteraction(true)
  }

  const prepareScrollToQna = (id) => {
    setItemToScrollTo({ item: id, timestamp: new Date().getTime() });
  }

  const fetchData = useCallback(async () => {
    try {
      const data = await fetchQna(qnaUrl, kn, lang);
      setKnowledgebase(data?.qnamaker[0]?.knowledgebase);
      setCategory(data?.qnamaker[0]?.data?.situations[0]?.situation_id);
      setCurrentLang(lang);
      setQnaMakerData(data);
    } catch (error) {
      console.error(error);
    }
  }, [qnaUrl, kn, lang])

  const { isLoading, error } = useQuery('fetchQna', async () => {
    fetchData()
  }, [fetchData]);

  const previousCateg = usePreviousCategory(activeCategory);
  const previousKnowledgebase = usePreviousKnowledgebase(activeKnowledgebase);
  const previousLanguage = usePreviousLanguage(activeLanguage);
  const previousItem = usePreviousItemToScrollTo(itemToScrollTo);

  useEffect(() => {
    setSpinnerDisplayed(isLoading || activeCategory == null || activeKnowledgebase == null);

    const container = faqOnly ? window : document.querySelector(`#${shadowRootId.current.id}`)?.shadowRoot?.querySelector('#scroll-container');
    const pageOffset = faqOnly ? 150 : 0;
    const shadowSelector = `#${shadowRootId.current.id}`

    if ((itemToScrollTo?.item != null) && itemToScrollTo.timestamp !== previousItem.timestamp) {
      const id = replaceSpecialChar(itemToScrollTo.item);

      scrollTo(`#${id}`, container, shadowSelector, pageOffset)
      return;
    }

    if (userInteraction && (previousKnowledgebase === activeKnowledgebase) && (previousCateg !== activeCategory)) {
      scrollTo('#questions', container, shadowSelector, pageOffset)
    }

    if (previousLanguage !== lang) {
      fetchData();
    }

  }, [setSpinnerDisplayed, activeCategory, activeKnowledgebase, isLoading, previousKnowledgebase,  itemToScrollTo, previousItem, previousCateg, userInteraction, shadowRootId, faqOnly, lang, previousLanguage, activeLanguage, setLanguage, fetchData]);

  return (
    <FaqWrapper className={faqOnly ? 'desktop-faq-only' : ''}>
      {faqOnly && <Spinner />}
      <FaqContainer id="scroll-container">
        {error && <div>Something went wrong...</div>}
        {isLoading || activeCategory == null || activeKnowledgebase == null || !qnaMakerData ? (<></>) :
          <>
            <div>
              <Knowledgebase
                qnamaker={qnaMakerData.qnamaker}
                setKb={setKb}
                activeKnowledgebase={activeKnowledgebase}
              />
            </div>

            {qnaMakerData.qnamaker.map(({ knowledgebase, data, qna }, index) => (
              <div key={`qna_categ_${index}`} style={{ 'paddingBottom': '6.625em' }}>
                <div key={`categ_${index}`}>
                  <Category
                    data={data}
                    setCateg={setCateg}
                    activeKnowledgebase={activeKnowledgebase}
                    activeCategory={activeCategory}
                    knowledgebase={knowledgebase}
                  />
                </div>

                <div key={`qna_${index}`}>
                  <Qna
                    qna={qna}
                    knowledgebase={knowledgebase}
                    activeCategory={activeCategory}
                    setCateg={setCateg}
                    prepareScrollToQna={prepareScrollToQna}
                  />
                </div>
              </div>
            ))}
          </>
        }
      </FaqContainer>
    </FaqWrapper>
  );
};

export default Faqs;
