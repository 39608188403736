const request = async (client, options) => {
  const onSuccess = (response) => {
    const { data } = response
    return data
  }

  const onError = (error) => Promise.reject(error.response)

  return client(options).then(onSuccess).catch(onError)
}

export default request
