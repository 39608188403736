const locales = (lang, string) => {
  return stringTranslation[string]?.[lang];
}

const stringTranslation = {
  'callCTA': {
    'en-us': 'Call Blue Cross',
    'fr-ca': 'Appeler Croix Bleue'
  },
  'reloadCTA': {
    'en-us': 'Start Over',
    'fr-ca': 'Recommencer'
  },
  'feedbackCTA': {
    'en-us': 'Rate our virtual assistant',
    'fr-ca': 'Évaluer notre assistant virtuel'
  },
  'confirmEndCTA': {
    'en-us': 'Submit and end discussion',
    'fr-ca': 'Soumettre et terminer la discussion'
  },
  'confirmNextCTA': {
    'en-us': 'Next',
    'fr-ca': 'Suivant'
  },
  'skipAndEndCTA': {
    'en-us': 'Skip and end',
    'fr-ca': 'Passer et fermer'
  },
  'skip': {
    'en-us': 'Skip',
    'fr-ca': 'Passer'
  },
  'cancelCTA': {
    'en-us': 'Cancel',
    'fr-ca': 'Annuler'
  },
  'endCTA': {
    'en-us': 'End the discussion',
    'fr-ca': 'Terminer la discussion'
  },
  'endHandover': {
    'en-us': 'End and go back to main menu',
    'fr-ca': 'Terminer et retour au menu principal'
  },
  'submitCTA': {
    'en-us': 'Submit',
    'fr-ca': 'Soumettre'
  },
  'languageCTA': {
    'fr-ca': "English",
    'en-us': "Français",
  },
  'toggleText': {
    'en-us': 'Toggle between Webchat or FAQ',
    'fr-ca': 'Basculer entre le clavardage ou la FAQ'
  },
  'closeChat': {
    'en-us': "Click to close chat window",
    'fr-ca': "Cliquer pour fermer la fenêtre de clavardage."
  },
  'privacy': {
    'en-us': 'Privacy Policy',
    'fr-ca': 'Politique de confidentialité',
  },
  'privacy-url': {
    'en-us': 'https://qc.bluecross.ca/privacy-policy',
    'fr-ca': 'https://qc.croixbleue.ca/politique-de-confidentialite',
  },
  'q&a': {
    'en-us': "Questions and Answers",
    'fr-ca': "Questions et réponses"
  },
  'faq': {
    'en-us': "Frequently Asked Questions",
    'fr-ca': "Foire aux questions",
  },
  'situation': {
    'fr-ca': "Sélectionnez votre situation",
    'en-us': "Select your situation",
  },
  'assurance_voyage': {
    'fr-ca': "Assurance voyage",
    'en-us': "Travel Insurance"
  },
  'askYourQuestion': {
    'fr-ca': "FAQ guidée",
    'en-us': "Guided FAQ"
  },
  'onboardingWelcome': {
    'fr-ca': "Je suis votre assistant virtuel. Vous pouvez me poser une question sur l'assurance voyage en lien avec la Covid-19 ou la reprise des voyages.",
    'en-us': "I'm your virtual assistant. You can ask me a question about travel insurance and Covid-19 or the resumption of travel."
  },
  'startBtn': {
    'fr-ca': "Commencer",
    'en-us': 'Get started'
  },
  'seeFaqBtn': {
    'fr-ca': "Consulter la FAQ Assurance Voyage",
    "en-us": "View Travel Insurance FAQ",
  },
  'hello': {
    'fr-ca': "Bonjour! 👋",
    'en-us': "Hello! 👋",
  },
  'rateFeedbackLabel': {
    'en-us': 'Blue Cross makes it easy for me to get answers to my questions via its Virtual assistant. (Move the slider to indicate your answer)',
    'fr-ca': 'Croix Bleue me permet d’obtenir facilement les réponses à mes questions via son Assistant virtuel. (Déplacez le curseur pour indiquer votre réponse)'
  },
  'rateFeedbackCommentLabel': {
    'en-us': 'How would you rate your experience with our virtual assistant?',
    'fr-ca': 'Comment évalueriez-vous votre expérience avec notre assistant virtuel?'
  },
  'rateRecommendToFriend': {
    'en-us': 'On a scale of 0 to 10, how likely are you to recommend Blue Cross to your friends and family? (Move the slider to indicate your answer)',
    'fr-ca': 'Sur une échelle de 0 à 10, quelle est la probabilité que vous recommandiez Croix Bleue à vos proches ? (Déplacez le curseur pour indiquer votre réponse)'
  },
  'feedbackRate0': {
    'fr-ca': '1 = Totalement en désaccord',
    'en-us': '1 = Totally disagree'
  },
  'feedbackRate1': {
    'fr-ca': '1 = Totalement en désaccord',
    'en-us': '1 = Totally disagree'
  },
  'feedbackRate2': {
    'fr-ca': '2 = En désaccord',
    'en-us': '2 = Disagree'
  },
  'feedbackRate3': {
    'fr-ca': '3 =  Plutôt en désaccord',
    'en-us': '3 = Somewhat disagree'
  },
  'feedbackRate4': {
    'fr-ca': '4 = Ni en accord ou en désaccord',
    'en-us': '4 = Neither agree nor disagree'
  },
  'feedbackRate5': {
    'fr-ca': '5 = Plutôt d\'accord',
    'en-us': '5 = Tend to agree'
  },
  'feedbackRate6': {
    'fr-ca': '6 = D\'accord',
    'en-us': '6 = Agree'
  },
  'feedbackRate7': {
    'fr-ca': '7 = Totalement d\'accord',
    'en-us': '7 = Totally agree'
  },
  'rate0': {
    'fr-ca': '0 = Pas du tout probable',
    'en-us': '0 = Not at all likely'
  },
  'rate10': {
    'fr-ca': '10 = Très probable',
    'en-us': '10 = Very likely'
  },
  'rateMotivation': {
    'fr-ca': "Qu’est-ce qui vous a motivé à attribuer cette note?",
    'en-us': "What motivated you to give this rating?"
  },
  'rateVirtual': {
    'fr-ca': "Que pouvons-nous améliorer pour optimiser votre expérience avec l'assistant virtuel ?",
    'en-us': "What can we improve to optimize your experience with the Virtual assistant?"
  },
  'otherCommentsFeedback': {
    'en-us': 'Other comment',
    'fr-ca': 'Autre commentaire'
  },
  'otherText': {
    'en-us': 'Other',
    'fr-ca': 'Autre'
  },
  'feedbackFormConfirmation' : {
    'en-us': 'Thanks for your comments!',
    'fr-ca': 'Merci de vos commentaires!'
  },
  'badScoreQuestion': {
    'en-us': 'I\'m sorry, what went wrong?',
    'fr-ca': 'Désolé qu\'est-ce qui n\'a pas fonctionné?'
  },
  'goodScoreQuestion': {
    'en-us': 'Great! What did you like about it?',
    'fr-ca': 'Super! Qu\'avez-vous apprécié?'
  },
  'technicalProblems': {
    'en-us': 'Technical problems',
    'fr-ca': 'Problèmes techniques'
  },
  'dissatisfiedAnswers': {
    'en-us': 'Dissatisfied with answers',
    'fr-ca': 'Insatisfait des réponses'
  },
  'wrongAnswer': {
    'en-us': 'Not the right answer',
    'fr-ca': 'Pas la bonne réponse'
  },
  'easyUse': {
    'en-us': 'Easy to use',
    'fr-ca': 'Facile à utiliser'
  },
  'greatHelp': {
    'en-us': 'Assistant was a great help',
    'fr-ca': 'Assistant d\'une grande aide'
  },
  'relevantAnswers': {
    'en-us': 'Relevant answers',
    'fr-ca': 'Réponses pertinentes'
  },
  'wasUseful': {
    'fr-ca': 'La réponse vous a été utile?',
    'en-us': "Was this helpful?"
  },
  'noSuggestionFound': {
    'fr-ca': 'Aucune suggestion trouvée pour ce terme.',
    'en-us': 'No suggestion found for this term.'
  },
  'botName': {
    'fr-ca': 'FAQ guidée',
    'en-us': 'Guided FAQ',
  },
  'you': {
    'fr-ca': 'Vous',
    'en-us': 'You',
  },
  'errorRequired': {
    'fr-ca': "Ceci est un champ obligatoire.",
    'en-us': "This is a required field."
  },
  'representative': {
    'fr-ca': "Spécialiste voyage",
    'en-us': "Travel specialist",
  },
  'closeLiveChat': {
    'fr-ca': "Fermer le clavardage avec le spécialiste",
    'en-us': "Close chat with specialist",
  },
  'agentName': {
    'fr-ca': "Spécialiste voyage",
    'en-us': "Travel Specialist",
  }
}

export default locales;