import * as React from "react"

const ErrorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#8E0900"
      d="M10.951 8.773c0-.604.47-1.094 1.048-1.094.58 0 1.049.49 1.049 1.094v4.378c0 .604-.47 1.094-1.049 1.094s-1.048-.49-1.048-1.094V8.773ZM12 15.34c-.58 0-1.049.49-1.049 1.094 0 .604.47 1.094 1.048 1.094.58 0 1.049-.49 1.049-1.094 0-.605-.47-1.095-1.049-1.095Z"
    />
    <path
      fill="#8E0900"
      fillRule="evenodd"
      d="M9.308 2.586C9.88 1.599 10.9 1 11.999 1c1.1 0 2.12.6 2.692 1.586l8.888 15.49a3.409 3.409 0 0 1 .008 3.267c-.553 1.013-1.58 1.644-2.709 1.657H3.11c-1.117-.013-2.144-.644-2.697-1.657A3.416 3.416 0 0 1 .43 18.06L9.308 2.586ZM2.236 19.17a1.136 1.136 0 0 0-.003 1.089c.184.338.527.548.888.552h17.746c.372-.004.714-.214.899-.552a1.13 1.13 0 0 0 .006-1.074l-8.876-15.47a1.04 1.04 0 0 0-.897-.526 1.04 1.04 0 0 0-.895.524L2.236 19.17Z"
      clipRule="evenodd"
    />
  </svg>
)

export default ErrorIcon
