import axios from 'axios';

const fetchQna = async (url, kn, lang) => {
  const HOST = `${url}/api/qna?kn=${encodeURIComponent(kn)}&lang=${encodeURIComponent(lang)}&nometa[hide_in_faq]=true`;

  const res = await axios(HOST, {
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  })

  return res.data;
}

export default fetchQna;
