import * as React from "react"

const RobotBubble = (props) => {
  return (
    <svg
      width={100}
      height={100}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#prefix__filter0_d)">
        <circle cx={50} cy={50} r={40} fill="#fff" />
      </g>
      <circle cx={35} cy={30} r={3} fill="#077AC0" />
      <circle cx={64} cy={30} r={3} fill="#077AC0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 48V31h2v17h-2zM63 49V31h2v18h-2z"
        fill="#077AC0"
      />
      <circle cx={49.5} cy={51.5} r={18.5} fill="#0094D7" />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={31}
        y={33}
        width={37}
        height={37}
      >
        <circle cx={49.5} cy={51.5} r={18.5} fill="#0094D7" />
      </mask>
      <g mask="url(#prefix__a)" fill="#2BA3EC">
        <path d="M36.63 33.804h2.413v34.587H36.63zM59.956 33.804h2.413v34.587h-2.413z" />
      </g>
      <rect
        x={38.155}
        y={44.069}
        width={22.69}
        height={10.534}
        rx={5.267}
        fill="#fff"
      />
      <circle cx={55.578} cy={49.336} r={2.026} fill="#0094D7" />
      <circle cx={43.422} cy={49.336} r={2.026} fill="#0094D7" />
      <path
        d="M53.362 59.465c.553 0 1.01.453.899.994a4.864 4.864 0 01-9.522 0c-.112-.541.348-.994.9-.994H53.362z"
        fill="#fff"
      />
      <defs>
        <filter
          id="prefix__filter0_d"
          x={0}
          y={0}
          width={100}
          height={100}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default RobotBubble;
