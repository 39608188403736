import styled from '@emotion/styled';

const AppContainer = styled.div`
  --color-primary: #077AC0;
  --color-primary-light: #E1F5FF;
  --color-primary-dark: #00588A;
  --color-primary-darker: #0B3349;
  --color-white: #FFF;
  --color-black: #333333;
  --color-gray: #BDBDBD;

  --chat-container-height: 1024px;
  --chat-container-width: 515px;
  --chat-footer-height: 54px;
  --chat-header-height: 60px;
  --chat-border-radius: 24px;

  margin: 0;

  * {
    font-style: normal !important;
    font-family: "Open Sans", Arial, Helvetica, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  em {
    font-style: italic !important;
  }

  & p,
  & a,
  & a:visited {
    color: inherit;
    margin: 0;
  }

  & button {
    cursor: pointer;
  }

  .rc-collapse-content-box,
  .ac-textBlock {
    ul {
      margin-left: 0;
      padding-left: 0;
      margin-top: 0.5em;
      margin-left: 1em;
      list-style: disc;
    }

    ul li {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 1.5em;

      font-size: 14px !important;

      @media screen and (min-width: 640px) {
        font-size: 18px !important;
      }
    }
  }

  .ac-textBlock {
    ul {
      padding-left: 4px;
    }
  }

  .rc-collapse-content-box {
    ul li {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 1.5em;

      font-size: 14px !important;

      @media screen and (min-width: 640px) {
        font-size: 16px !important;
      }
    }
  }

  .ac-textBlock {
    ul li {
      font-size: 14px !important;

      @media screen and (min-width: 640px) {
        font-size: 18px !important;
      }

      p {
        font-size: 14px !important;

        @media screen and (min-width: 640px) {
          font-size: 18px !important;
        }
      }
    }
  }
`;

const Button = styled.button`
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SrOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
`;

const Container = styled.div`
  height: 100%;
  background: white;
`

const ButtonMinimize = styled(Button)`
  position: absolute;
  height: 100%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

const ButtonMinimizeOnboarding = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 46px;
  width: 46px;
  padding: 0;
  transform: translateX(10px) translateY(-100%);
  cursor: pointer;
  transition: all .2s ease-in;

  svg {
    z-index: 1;
  }

  &::before {
    content: "";
    border-radius: 4px;
    background: var(--color-primary);
    padding: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    transition: background-color 0.2s ease-in-out;
  }

  &:hover {
    &::before {
      content: "";
      border-radius: 4px;
      background: var(--color-primary-dark);
    }
  }
`;

const StyledMenuButton = styled.button`
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${props => props.paddingX || "21px"};
  height: 40px;
  left: 24px;
  top: 16px;
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  border: 2px solid #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  justify-content: ${props => props.justifyContent || "flex-start"};
  color: #077AC0;

  span {
    width: 100%;
  }

  svg + span {
    text-align: left;
    padding-left: 16px;
  }
`;

const StyledAltMenuButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 21px;
  height: 40px;
  left: 24px;
  top: 16px;
  background: transparent;
  border-radius: 4px;
  width: 100%;
  border: 2px solid transparent;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  justify-content: ${props => props.justifyContent || "flex-start"};
  color: #fff;
  text-decoration: underline;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: var(--chat-footer-height);
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.0);
  z-index: 10;
  height: auto !important;
  visibility: hidden;
  transition: all 200ms ease;
  transition-property: visibility, background-color;

  &.menu-open {
    background-color: rgba(0, 0, 0, 0.2);
    visibility: visible;
  }
`;

const StyledNav = styled.nav`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 15;
  background-color: var(--color-primary-dark);
  display: flex;
  justify-content: center;
  border-radius: 16px 16px 0px 0px;
  margin-bottom: var(--chat-footer-height);
  min-height: 104px;

  transform: translateY(10%);
  opacity: 0;
  visibility: hidden;

  transition: all 200ms ease;
  transition-property: visibility, opacity, transform;

  &.menu-open {
    visibility: visible;
    opacity: 100;
    transform: translateY(0%);
  }

  ul  {
    padding: 0;
    padding-top: 16px;
    margin: 0;
    list-style: none;
    width: 100%;
    overflow: auto;
  }

  li {
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export { AppContainer, Button, SrOnly, Container, ButtonMinimize, ButtonMinimizeOnboarding, StyledMenuButton, StyledAltMenuButton, Overlay, StyledNav };
