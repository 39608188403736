import styled from "@emotion/styled"
import removeNonBreakingSpace from "../../helpers/replaceNonBreakingSpace"

const HeroCard = ({next, card}) => {
  const styledCss = card.attachment.content?.buttons.reduce((prev, curr, index) => {
    let style = ''

    if (card?.attachment?.content?.text != null) {
      card.attachment.content.text = removeNonBreakingSpace(card.attachment.content.text);
    }

    if (curr.type === 'openUrl') {
      style = `
        & + .webchat__adaptive-card-renderer {
          .ac-actionSet button:nth-of-type(${index + 1}) {
            background: var(--color-white) !important;
            border: 2px solid var(--color-primary) !important;
            box-sizing: border-box;
            transition: border-color 0.2s ease-in-out;

            &:hover {
              border: 2px solid #00588A !important;
              color: #00588A !important;

              > div {
                color: #00588A !important;

                &:after {
                  filter: brightness(0.75);
                }
              }
            }

            & > div {
              color: var(--color-primary) !important;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: color 0.2s ease-in-out;

              &:after {
                font-size: 16px;
                transition: filter 0.2s ease-in-out;
                display: inline-block;
                margin-left: 1em;
                content: '';
                height: 1.25em;
                min-width: 1.25em;
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5858 2H13C12.4477 2 12 1.55228 12 1C12 0.447715 12.4477 0 13 0H19C19.1356 0 19.2649 0.0269855 19.3828 0.0758788C19.5007 0.124679 19.6112 0.197017 19.7071 0.292893C19.803 0.38877 19.8753 0.499271 19.9241 0.617216C19.973 0.735123 20 0.86441 20 1V7C20 7.55228 19.5523 8 19 8C18.4477 8 18 7.55228 18 7V3.41421L8.70711 12.7071C8.31658 13.0976 7.68342 13.0976 7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L16.5858 2Z' fill='%230094D7'/%3E%3Cpath d='M15 11C15 10.4477 15.4477 10 16 10C16.5523 10 17 10.4477 17 11V17C17 18.6569 15.6569 20 14 20H3C1.34315 20 0 18.6569 0 17V6C0 4.34315 1.34315 3 3 3H9C9.55229 3 10 3.44772 10 4C10 4.55228 9.55229 5 9 5H3C2.44772 5 2 5.44772 2 6V17C2 17.5523 2.44772 18 3 18H14C14.5523 18 15 17.5523 15 17V11Z' fill='%230094D7'/%3E%3C/svg%3E%0A");
              }
            }
          }
        }
      `
    }
    return `${prev}${style}`;
  }, '')

  const StyledDiv = styled.div`
    ${styledCss}

    .ac-container .ac-adaptiveCard {
      padding: 0.5em 1em !important;
      font-size: 14px;

      @media screen and (min-width: 640px) {
        padding: 1.063em 1.313em !important;
        font-size: 18px;
      }
    }

    .ac-textBlock,
    .ac-container,
    .ac-container p {
      font-size: 14px !important;

      @media screen and (min-width: 640px) {
        font-size: 18px !important;
      }
    }
  `;

  return (
    <StyledDiv className='hero-card-custom' />
  )
}

export default HeroCard;