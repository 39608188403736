import classNames from 'classnames';
import WebChat from '../WebChat';
import Faqs from '../Faqs';
import RobotBubble from '../../icons/RobotBubble';
import Footer from '../Footer';
import Header from '../Header';
import Spinner from '../Spinner';
import locales from '../../locales';
import { useWebChat } from '../../context';
import { use100vh } from 'react-div-100vh'
import { MinimizeBox, ChatWrapper, BubbleButton, SpanText, SpanIcon, StatusIcon, StatusIconRed, ChatContainer } from "./styled";
import { SrOnly, ButtonMinimizeOnboarding } from '../../styledComponents';
import MinimizeIcon from '../../icons/MinimizeIcon';
import { useEffect } from 'react';
import Autocomplete from '../Autocomplete';

const Bubble = ({ qnaUrl = '', kn = '' }) => {
  const {
    visible,
    minimized,
    setMinimized,
    loaded,
    newMessage,
    handleMaximizeButtonClick,
    activeTab,
    isOnboardingStyle,
    appLoaded,
    handleMinimizeButtonClick,
    setIsOnboardingStyle,
    storageHideOnboarding,
    setStorageHideOnboarding,
    onboardingAnimationEnded,
    lang
  } = useWebChat();

  const isDesktopWidth = window.matchMedia('(min-width: 640px)');
  const isDesktopHeight = window.matchMedia('(min-height: 640px) and (min-width: 1024px)')
  const height = use100vh()
  const adjustedHeight = isOnboardingStyle ? 'fit-content' : isDesktopWidth.matches && isDesktopHeight.matches ? (height * 0.96) : height;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (storageHideOnboarding === 'true') {
      setIsOnboardingStyle(false);
      setMinimized(true);
    }
  }, []); // The empty [] is on purpose, it's so it behaves like componentDidMount
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleCloseOnboarding = () => {
    setStorageHideOnboarding('true');
    handleMinimizeButtonClick();
  }

  return (
    <MinimizeBox style={{display: visible ? 'block' : 'none'}} className={classNames('minimizable-web-chat', isOnboardingStyle ? 'is-onboarding' : '')}>
      {(minimized || (!minimized && isOnboardingStyle)) && (
        <BubbleButton className="maximize" onClick={handleMaximizeButtonClick}>
          <SpanIcon><RobotBubble /></SpanIcon>
          {newMessage ? <StatusIconRed /> : <StatusIcon />}
          {!isOnboardingStyle &&
            <SpanText>{locales(lang, 'askYourQuestion')}</SpanText>
          }
        </BubbleButton>
      )}
      {loaded && (
        <ChatWrapper style={{ height: adjustedHeight, opacity: (appLoaded || !isOnboardingStyle) ? 1 : 0 }} className={classNames('chat-box right bottom', minimized ? 'hide' : '')}>
          {(!minimized && isOnboardingStyle) && (
            <ButtonMinimizeOnboarding onClick={handleCloseOnboarding} style={{ opacity: onboardingAnimationEnded ? 1 : 0 }} >
              <MinimizeIcon />
              <SrOnly>{locales(lang, 'closeChat')}</SrOnly>
            </ButtonMinimizeOnboarding>
          )}
          {!isOnboardingStyle &&
            <Header />
          }
          <ChatContainer display={activeTab ? 'block' : 'none'}>
            <WebChat className="react-web-chat" />
          </ChatContainer>

          {
            activeTab === false && <Faqs qnaUrl={qnaUrl} kn={kn} />
          }

          {!isOnboardingStyle &&
            <>
              <Footer />
              <Spinner />
            </>
          }

          <Autocomplete />
        </ChatWrapper>
      )}
    </MinimizeBox>
  );
};

export default Bubble;
