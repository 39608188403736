import styled from '@emotion/styled';
import { Button } from "../../../styledComponents";

const ChatHeader = styled.header`
  height: var(--chat-header-height);
  background: var(--color-primary-dark);
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
`;

const ButtonMinimize = styled(Button)`
  position: absolute;
  height: 100%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

const ButtonHome = styled(Button)`
  background: transparent;
  border: none;
  position: absolute;
  left: 8px;
  padding: 8px;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0.5em;
  height: 100%;
  display: flex;
`;

const ButtonIcon = styled(Button)`
  position: relative;
  height: 100%;
  padding-left: .75em;
  padding-right: .75em;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 2em;
    width: 2em;
    border-radius: 4px;
    z-index: 0;
  }

  &:hover::before {
    background-color: var(--color-primary-darker);
  }

  svg {
    position: relative;
    z-index: 1;
  }
`;

export { ChatHeader, ButtonMinimize, ButtonHome, ButtonsContainer, ButtonIcon };
