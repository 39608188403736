import fscreen from 'fscreen';

export const goFullscreen = (element) => {
  if (navigator.userAgent.match(/iPhone/i)) {
    window.scrollTo(0, 1);
    document.body.classList.add('cb-chat-open-modal');
  }

  if (fscreen.fullscreenEnabled === false) {
    return;
  }

  fscreen.requestFullscreen(element);
}

export const exitFullscreen = () => {
  document.body.classList.remove('cb-chat-open-modal');

  if (fscreen.fullscreenEnabled === false || fscreen.fullscreenElement === null) {
    return;
  }

  fscreen.exitFullscreen();
}
