import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid'
import { Container } from '../../../styledComponents';

const MinimizeBox = styled.div`
  position: relative;

  & > button.maximize > .red-dot {
    color: #ef0000;
    font-size: 0.875em;
    position: absolute;
    right: 0.75em;
    top: 0.75em;
  }

  & > button.maximize:focus:not(:active),
  & > button.maximize:hover:not(:active) {
    background-color: #069;
    border-color: #069;
  }

  & > button.maximize:active {
    background-color: White;
    color: #39c;
  }

  & > .chat-box.hide {
    display: none;
  }

  &:not(.is-onboarding) > .chat-box.right {
    right: 0px;
  

    @media screen and (min-width: 768px) and (min-height: 768px) {
      right: 30px;
      left: auto;
    }

    @media screen and (min-width: 1024px) and (min-height: 1024px) {
      right: 60px;
      left: auto;
    }
  }

  &:not(.is-onboarding) > .chat-box.bottom {
    bottom: 0;
    top: 0;
    margin: auto;

    @media screen and (min-width: 640px) and (min-height: 640px) {
      bottom: 1em;
      top: auto;
    }

    @media screen and (min-width: 1024px) and (min-height: 1024px) {
      bottom: 2em;
      top: auto;
    }
  }

  &.is-onboarding > .chat-box,
  &.is-onboarding > .chat-box > div,
  &.is-onboarding > .chat-box > div > div {
    height: fit-content;
  }

  & > .chat-box > header > .filler {
    flex: 0 10000 100%;
  }

  & > .chat-box > header > button:focus:not(:active),
  & > .chat-box > header > button:hover:not(:active) {
    color: rgba(255, 255, 255, 0.6);
  }

  & > .chat-box > header > button:focus:active {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const ChatWrapper = styled.div`
  max-width: none;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 1.25em rgba(0, 0, 0, 0.2);
  bottom: 1.25em;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: auto;
  bottom: auto;
  border-radius: 0;
  overflow: hidden;
  background: none;
  border: none;
  z-index: 1102;

  @media screen and (min-width: 640px) and (min-height: 768px) {
    border-radius: var(--chat-border-radius);
    max-width: var(--chat-container-width);
    height: var(--chat-container-height);
    max-height: calc(100vh - 2em);
  }

  @media screen and (min-width: 1024px) {
    border-radius: var(--chat-border-radius);
    max-width: var(--chat-container-width);
    height: var(--chat-container-height);
    max-height: calc(100vh - 2em);
  }

  & > div {
    height: 100%;
    min-height: 0;
  }

  .is-onboarding & {
    backdrop-filter: none;
    box-shadow: none;
    border-radius: 0 !important;
    margin: 0;
    margin-top: auto !important;
    width: calc(100% - 4rem);
    right: 1em;
    bottom: 100px;

    @media screen and (min-width: 640px) and (min-height: 768px) {
      right: 30px;
    }

    @media screen and (min-width: 1024px) and (min-height: 1024px) {
      right: 60px;
    }

    &,
    .webchat__basic-transcript,
    .webchat__basic-transcript__scrollable {
      overflow: visible !important;
    }

    .webchat__basic-transcript__activity--acknowledged {
      padding: 0 !important;
    }

    .webchat__basic-transcript__activity-box:not(:empty) {
      padding-bottom: 0 !important;
      margin-bottom: 0.25em !important;
    }

    .webchat__stacked-layout__avatar-gutter,
    .webchat__stacked-layout__status,
    .webchat__bubble > .webchat__bubble__nub-pad {
      display: none !important;
    }

    .webchat__send-box__main {
      display: none;
    }

    & > div,
    div[role="complementary"] {
      background: transparent !important;
    }

    .webchat__basic-transcript__activity--from-bot .webchat__basic-transcript__activity-box {
      margin-left: 2.375em;
      margin-right: 0;
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.2);

      > .webchat__stacked-layout {
        margin-left: 0;
      }

      .webchat__stacked-layout__content {
        box-shadow: 0 0 1.25em rgba(0, 0, 0, 0.2);
      }

      > div {
        margin-right: 0;
      }
    }

    .webchat__suggested-action {
      margin-left: 0 !important;
      margin-right: 0!important;
    }

    .webchat__bubble__content {
      background-color: #fff !important;
    }

    .webchat__suggested-actions__stack {
      overflow: visible !important;
    }

    .webchat__suggested-action__button {
      box-shadow: 0 0 0.5em rgb(0 0 0 / 20%);
    }

    .webchat__basic-transcript__activity,
    .webchat__suggested-action__button {
      opacity: 0;
      visibility: hidden;
      bottom: 0;
      transition: all .2s ease-in;
    }

    .webchat__basic-transcript__activity.js-animated,
    .webchat__suggested-action__button.js-animated {
      animation: chatboxesFadeInUp .25s ease-in forwards;
    }

    .webchat__basic-transcript__activity.js-animated {
      animation-delay: 1s;
    }

    .webchat__suggested-action__button.js-animated {
      animation-delay: .5s;
    }

    .bot-custom-name,
    .user-custom-name {
      display: none;
    }

    .webchat__stacked-layout__content {
      margin-bottom: 0;
    }
  }

  @keyframes chatboxesFadeInUp {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  .minimizable-web-chat:not(.is-onboarding) & {
    .webchat__basic-transcript__activity,
    .webchat__suggested-action__button {
      position: relative !important;
    }
  }

  .webchat__stacked-layout__content {
    overflow: visible !important;
    margin-bottom: 16px;
  }

`;

const BubbleButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.875em;
  width: 14.875em;
  border-radius: 50px;
  padding: 0.688em 1em 0.688em 3.438em;
  background-color: var(--color-primary);
  border: none;
  position: fixed;
  z-index: 1000;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  right: 0.25em;
  bottom: 1.5em;

  @media screen and (min-width: 640px) and (min-height: 768px) {
    right: 30px;
    bottom: 2em;
  }

  @media screen and (min-width: 1024px) and (min-height: 1024px) {
    right: 60px;
    bottom: 2em;
  }

  &:hover {
    background-color: var(--color-primary-dark) !important;
  }

  .is-onboarding & {
    width: 1px !important;
    padding: 0 !important;
    right: 3em;

    @media screen and (min-width: 640px) and (min-height: 768px) {
      right: 70px;
    }

    @media screen and (min-width: 1024px) and (min-height: 1024px) {
      right: 100px;
    }
  }
`;

const SpanText = styled.span`
  font-weight: bold;
  font-size: 1em;
  line-height: 1.5em;
  color: var(--color-white);
  white-space: nowrap;
`;

const SpanIcon = styled.span`
  position: absolute;
  transform: translateX(-50%);
  height: 100px;
  width: 100px;
  left: 0px;
  top: 0px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom:0;
  margin: auto 0;
`;

const StatusIcon = styled.span`
  height: 0.625em;
  width: 0.625em;
  border-radius: 100%;
  background: #219653;
  transform: matrix(1, 0, 0, -1, 0, 0);
  position: absolute;
  left: 28px;
  top: -6px;
  z-index: 5;
`;

const StatusIconRed = styled(StatusIcon)`
  background: #e96e2c;
`;

const ChatContainer = styled(Container, {
  shouldForwardProp: prop =>
    isPropValid(prop) && prop !== 'display'
})(({ display }) => ({
  display
}))

export { MinimizeBox, ChatWrapper, BubbleButton, SpanText, SpanIcon, StatusIcon, StatusIconRed, ChatContainer };